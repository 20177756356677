import { Injectable } from '@angular/core';

import { ActionWithPayload } from '../../interfaces/actionWithPayload';
import { ActionTypes, Actions } from '../../store/locale/locale.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()

/**
 * Class representing the Locale service.
 */
export class LocaleService {

	/**
	 * Constructor.
	 * @param {TranslateService} translate
	 * @return {void}
	 */
	constructor(
		private translate: TranslateService) {
	}

	/**
	 * Dispatch an action to change the state for 'current'.
	 * @private
	 * @param {boolean} locale - The new locale (i.e. 'nl')
	 * @return  {Actions}
	 */
	setCurrent(locale: string): Actions {

		// Loads translation labels for 'new' locale
		this.translate.use(locale);

		// Saves locale to sessionStorage;
		sessionStorage.setItem('locale', locale);

		return {
			type: ActionTypes.SET_CURRENT_LOCALE,
			payload: locale
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
