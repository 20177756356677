import { Component, Input, Output, OnInit, OnChanges, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Filter, Layout } from '../../../../store/state.model';
import { Location } from '../../../../store/locations/locations.models';
import { Category } from '../../../../store/categories/categories.models';
import { Recipe, RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { initialFilterState } from '../../../../store/filter/filter.reducer';
import { BaseComponent } from '../../../base/base.component';
import { _, Moment } from '../../../../tools';
import { DateTimeHelper } from '../../../../helpers';

const Flatpickr = require('flatpickr');
const Dutch = require('flatpickr/dist/l10n/nl.js').nl;

@Component({
	selector: 'filter-form',
	template: require('./filter-form.component.html')
})

/**
 * Class representing the FilterFormComponent component.
 */
export class FilterFormComponent extends BaseComponent implements OnInit, OnChanges {

	/**
	 * @type {ElementRef} - The element on which to bind the datepicker.
	 */
	@ViewChild('dateInput') dateInput: ElementRef;

	/**
	 * @type {boolean} - The filter mode.
	 */
	@Input() isActive: boolean;

	/**
	 * @type {array} - Current locations.
	 */
	@Input() locations: Array<Location>;

	/**
	 * @type {array} - Current categories.
	 */
	@Input() categories: Array<Category>;

	/**
	 * @type {array} - Current recipes.
	 */
	@Input() recipes: Array<RecipeEnriched>;

	/**
	 * @type {Layout} - Current layout state.
	 */
	@Input() layout: Layout;

	/**
	 * @type {string} - Current locale.
	 */
	@Input() locale: string;

	/**
	 * @type {Filter} - Current filter.
	 */
	@Input() filter: Filter;

	/**
	 * @type {boolean} - isAdHoc registration
	 */
	@Input() isAdHoc: boolean;

	/**
	 * @type {number} - The number of results for the current filter.
	 */
	@Input() noResults: number = 0;

	/**
	 * @type {EventEmitter} - The component apply event emitter.
	 */
	@Output() apply: EventEmitter<any> = new EventEmitter();

	/**
	 * @type {EventEmitter} - The component toggleIsCategoriesExpanded event emitter.
	 */
	@Output() toggleIsCategoriesExpanded: EventEmitter<any> = new EventEmitter();

	/**
	 * @type {FormControl} - The search query field.
	 */
	query: FormControl = new FormControl();

	/**
	 * @type {boolean} - Is the form touched or not?
	 */
	isTouched: boolean = false;

	/**
	 * @type {object} - The datePicker element.
	 */
	datePicker: any;

	/**
	 * @type {object} - Array of availableDates.
	 */
	availableDates: any[];

	/**
	 * @type {string} - The currentTime.
	 */
	currentTime: string;

	/**
	 * Class constructor.
	 * @param {Router} router
	 * @return {void}
	 */
	constructor(
		private router: Router) {
		super();
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		this.availableDates = this.getDates(Date.now(), Moment(Date.now()).add(2, 'months'));
		this.setDateAndTimeFilter();

		// Adds debouncer to the query input field
		this.addSubscription(this.query.valueChanges.pipe(
			debounceTime(400),
			distinctUntilChanged())
			.subscribe(value => {
				this.onApply({ query: value });
			})
		);
	}

	/**
	 * Upon changes detected.
	 * @return {void}
	 */
	ngOnChanges(): void {
		this.isTouched = this.isTouched || JSON
			.stringify(initialFilterState) !== JSON
				.stringify(this.filter);

		// if (!this.isAdHoc) {

		// this.recipes.map(recipe => {
		// 	return recipe.availability.map(slot => slot.date);
		// });

		const flatPickrConfig = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.filter.date ? this.filter.date : Moment(Date.now()).format('YYYY-MM-DD'),

			// Enables currently available dates
			enable: this.isAdHoc ? Moment(Date.now()).format('YYYY-MM-DD') : this.availableDates,

			// On changing (and thus selecting) the date
			onChange: (selectedDates, dateStr, instance) => {
				this.onApply({ date: dateStr });
			}
		};

		if (this.availableDates.length) {
			setTimeout(() => {
				this.datePicker = new Flatpickr(
					this.dateInput.nativeElement, this.locale === 'nl'
						? { ...flatPickrConfig, locale: Dutch } : flatPickrConfig
				);
			});
		}
		// }
	}

	/**
	 * Emit apply event to the parent component.
	 * @param {object} $event
	 * @return {void}
	 */
	onApply($event: any): void {
		this.apply.emit($event);
	}

	getDates(startDate, endDate) {
		let dateArray = [];
		let currentDate = Moment(startDate);
		let stopDate = Moment(endDate);
		while (currentDate <= stopDate) {
			dateArray.push(Moment(currentDate).format('YYYY-MM-DD'));
			currentDate = Moment(currentDate).add(1, 'days');
		}
		return dateArray;
	}

	getCurrentTime(stepMinutes) {
		let minutes = DateTimeHelper.getMinutes(Moment(Date.now()).format('HH:mm'));
		minutes = (minutes % stepMinutes !== 0)
			? Math.ceil(minutes / stepMinutes) * stepMinutes
			: minutes;
		return this.isAdHoc ? DateTimeHelper.getTime(minutes - 30) : DateTimeHelper.getTime(minutes);
	}

	hasPassedClosingTime(closingTime, currentTimeInMinutes) {
		const midnightTimeInMinutes = DateTimeHelper.getMinutes('23:59');
		const closingTimeInMinutes = DateTimeHelper.getMinutes(closingTime);
		return currentTimeInMinutes >= closingTimeInMinutes && currentTimeInMinutes <= midnightTimeInMinutes;
	}

	hasPassedMidnight(openingTime, currentTimeInMinutes) {
		const openingTimeInMinutes = DateTimeHelper.getMinutes(openingTime);
		return currentTimeInMinutes < openingTimeInMinutes;
	}

	setDateAndTimeFilter() {
		const openingTime = '07:00';
		const closingTime = '22:00';
		const currentTimeInMinutes = DateTimeHelper.getMinutes(Moment(Date.now()).format('HH:mm'));
		if (this.hasPassedClosingTime(closingTime, currentTimeInMinutes)) {
			this.filter.date = Moment(Date.now()).add(1, 'days').format('YYYY-MM-DD');
			this.filter.time = openingTime;
			console.log('hasPassedClosingTime');
		} else if (this.hasPassedMidnight(openingTime, currentTimeInMinutes)) {
			this.filter.date = Moment(Date.now()).format('YYYY-MM-DD');
			this.filter.time = openingTime;
			console.log('hasPassedMidnight');
		} else {
			this.filter.date = Moment(Date.now()).format('YYYY-MM-DD');
			this.filter.time = this.getCurrentTime(30);
		}
	}

	/**
	 * Emit toggleIsCategoriesExpanded event to the parent component.
	 * @param {object} $event
	 * @return {void}
	 */
	onToggleIsCategoriesExpanded($event: any): void {
		this.toggleIsCategoriesExpanded.emit($event);
	}

	/**
	 * On submitting the form, route to /overview.
	 * @return {void}
	 */
	onSubmit(): void {
		this.router.navigate(['/reservation/overview']);
	}
}
