import { Injectable } from '@angular/core';
import { ActionTypes, Actions } from '../../store/feedback/feedback.actions';
import { Notification } from '../../store/feedback/feedback.models';

@Injectable()

/**
 * Class representing the FeedbackService service.
 */
export class FeedbackService {

	/**
	 * Add a notification
	 * @param {Notifcation} notification
	 * @return  {Actions}
	 */
	addNotification(notification: Notification): Actions {
		return {
			type: ActionTypes.ADD_NOTIFICATION,
			payload: notification
		};
	}

	/**
	 * Dismiss a notification
	 * @param {number} notificationId
	 * @return  {Actions}
	 */
	dismissNotification(notificationId: number): Actions {
		return {
			type: ActionTypes.DISMISS_NOTIFICATION,
			payload: notificationId
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return  {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}