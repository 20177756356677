import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Headers, Response } from '@angular/http';
import { map } from 'rxjs/operators';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface, Locations, Cabinets, Categories, ManagementRecipes } from '../../../store/state.model';
import { ManagementRecipeEnriched } from '../../../store/management-recipes/management-recipes.models';
import { LayoutService, AuthenticationService, ManagementRecipesService } from '../../../services';
import { RecipesHelper } from '../../../helpers/recipes/recipes.helper';
import { BaseComponent } from '../../base/base.component';
import { _, getState, tassign } from '../../../tools';

@Component({
	template: require('./recipes-view.component.html')
})

/**
 * Class representing the RecipesViewcomponent component.
 */
export class RecipesViewComponent extends BaseComponent implements OnInit {

	didLoadRecipes: boolean = false;

	/**
	 * The locations state.
	 * @type {Locations}
	 */
	locations: Locations;

	/**
	 * The categories state.
	 * @type {Categories}
	 */
	categories: Categories;

	/**
	 * The cabinets state.
	 * @type {Cabinets}
	 */
	cabinets: Cabinets;

	/**
	 * The recipes state.
	 * @type {Recipes}
	 */
	recipes: ManagementRecipes;

	/**
	 * The recipes state.
	 * @type {Recipes}
	 */
	filteredRecipes: Array<ManagementRecipeEnriched>;

	availableLabels: string[] = [ 'unavailable', 'overdue', 'defect', 'lent' ];
	selectedLabel: number = -1;
	currentFilterQuery: string;

	/**
 	 * Constructor.
	 * @param {LayoutService} layoutService
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private managementRecipesService: ManagementRecipesService,
		private store: Store<StateInterface>) {
		super();

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets'))
			.subscribe((cabinets: Cabinets) => {
				this.cabinets = _.cloneDeep(cabinets);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);
			})
		);

		// Fetch new list of recipes
		this.managementRecipesService.hydrateManagementRecipes().pipe(
		map((res: Response) => this.authenticationService.doStoreBearer(res)))
		.subscribe((data: ApiResponse) => {
			const { success, result } = data;

			if (success) {
				this.didLoadRecipes = true;
				this.store.dispatch(
					this.managementRecipesService.loadRecipes(result)
				);
				return;
			}

			this.store.dispatch(
				this.authenticationService.doHandleError(data, this.store.dispatch(
					this.managementRecipesService.setIsLoading(false)
				))
			);
		}, error => this.store.dispatch(this.authenticationService
			.doHandleError(error, this.store.dispatch(
				this.managementRecipesService.setIsLoading(false)
			))
		));

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('managementRecipes'))
			.subscribe((recipes: ManagementRecipes) => {
				if (this.didLoadRecipes) {
					this.recipes = _.cloneDeep(recipes);
					let recipes_available: any[] = getState(this.store).recipes.items;

					if (this.recipes) {
						// Adds metadata
						this.recipes.items = this.recipes.items.map(recipe => ({
							...RecipesHelper.getEnrichedManagementRecipe(
								recipe,
								recipes_available.find(recipe_available => recipe_available.id === recipe.id),
								getState(store)
							)
						}));

						// Sets filtered recipes
						this.filteredRecipes = [...this.recipes.items];
					}
				}
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Resets page heaer navigation
		this.store.dispatch(
			this.layoutService.resetState()
		);
	}

	applyLabelFilter(index: number): void {
		this.selectedLabel = index;
		const { items } = this.recipes;

		this.applyFilter(this.currentFilterQuery);
		this.filteredRecipes = this.filteredRecipes.filter((recipe: any) => {
			const { labels } = recipe;
			if (this.selectedLabel === -1) {
				return true;
			}
			return labels.find((label: string) => label === this.availableLabels[this.selectedLabel]);
		});
	}

	/**
	 * Apply a search query filter.
	 * @param {string} query
	 */
	applyFilter(query: string): void {
		const { items } = this.recipes;
		const { current } = getState(this.store).locale;

		this.currentFilterQuery = query;

		this.filteredRecipes = items.filter(recipe => {
			const { name, category, location } = recipe;
			const hasQuery = (haystack, needle) => haystack.search(new RegExp(needle, 'i')) !== -1;

			if (query) {

				// Searches recipe metadata
				let inName = hasQuery(name[current], query);
				let inCategory = category ? hasQuery(category.name[current], query) : false;
				let inLocation = location ? hasQuery(location.name[current], query) : false;

				return (inName || inCategory || inLocation) || false;
			}
			return true;
		});
	}
}
