import { Injectable, Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../auth/authentication.service';
import { StateInterface, Account, Registration } from '../../store/state.model';
import { HeadersHelper, DateTimeHelper } from '../../helpers';
import { _, getState, tassign, Moment } from '../../tools';

import 'rxjs/Rx';

export interface User {
	ID: number;
	UserID: string;
	FirstName: string;
	LastName: string;
	Language: string;
	CardCode: string;
	CreateDT: string;
	LastLogonDT: string;
	LastSyncDT: string;
	IsDirty: boolean;
	IsDeleted: boolean;
	DeleteDT: string;
	UpdateDT: string;
	ConditionsAccepted: boolean;
}

@Injectable()

/**
 * Class representing the Registration service.
 */
export class UsersService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {AuthenticationService} authenticationService
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private authenticationService: AuthenticationService,
		private http: Http,
		private store: Store<StateInterface>
	) {}

	/**
	 * Performs a call to get all users.
	 * @return {Observable}
	 */
	getUsers(): Observable<any> {
		// Posts data
		return this.http
			.get(`${this.apiEndpoint}cabinetUser`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to update a user.
	 * @return {Observable}
	 */
	createUser(data: any): Observable<any> {
		// Posts data
		return this.http
			.post(`${this.apiEndpoint}cabinetUser`, data, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to update a user.
	 * @return {Observable}
	 */
	updateUser(userId: number, data: any): Observable<any> {
		// Posts data
		return this.http
			.patch(`${this.apiEndpoint}cabinetUser/` + userId, data, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to delete a user.
	 * @return {Observable}
	 */
	deleteUser(userId: number): Observable<any> {
		// Posts data
		return this.http
			.delete(`${this.apiEndpoint}cabinetUser/` + userId, HeadersHelper.getOptions(this.store));
	}
}
