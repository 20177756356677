import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { StateInterface, Feedback } from '../../../store/state.model';
import { FeedbackService } from '../../../services';

@Component({
	selector: 'feedback',
	template: require('./feedback.component.html')
})

/**
 * Class representing the Feedback component.
 */
export class FeedbackComponent {

	/**
	 * @type {Feedback}
	 */
	@Input() feedback: Feedback;

	/**
	 * Constructor.
	 * @param {FeedbackService} feedbackService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private feedbackService: FeedbackService,
		private store: Store<StateInterface>) {
	}

	/**
	 * Dismiss a notification.
	 * @param {numer} notificationId
	 * @return {void}
	 */
	onDismissNotification(notificationId: number = 0): void {
		this.store.dispatch(
			this.feedbackService.dismissNotification(notificationId)
		);
	}
}