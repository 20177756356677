import { ActionReducer } from '@ngrx/store';
import { ActionTypes, Actions } from './filter.actions';
import { FilterInterface as Filter } from './filter.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialFilterState: Filter = {
	query: '',
	categoryId: null,
	locationId: null,
	date: null,
	time: null,
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Filter} state - the current state
 * @return {Filter} The new state
 */
export const filter: ActionReducer<any, Actions> = (state: Filter = initialFilterState, action: Actions): Filter => {
	switch (action.type) {

		case ActionTypes.EDIT_FILTER:
			return tassign(state, action.payload);

		case ActionTypes.RESET_STATE:
			return initialFilterState;

		case ActionTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.payload
			};
	}
	return state;
};
