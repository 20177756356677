import { Action } from '@ngrx/store';

export const ActionTypes = {
	STORE_BEARER: '[Auth] STORE_BEARER',
	LOAD_ACCOUNT: '[Auth] LOAD_ACCOUNT',
	SET_IS_LOGGED_IN: '[Auth] SET_IS_LOGGED_IN',
	EDIT_FORMDATA: '[Auth] EDIT_FORMDATA',
	RESET_STATE: '[Auth] RESET_STATE',
	SET_IS_NEW: '[Auth] SET_IS_NEW',
	SET_IS_LOADING: '[Auth] SET_IS_LOADING',
	SET_CONDITIONS_READ: '[Auth] SET_CONDITIONS_READ'
};

export class StoreBearerAction implements Action {
	readonly type = ActionTypes.STORE_BEARER;
	constructor(public payload?: any) {}
}

export class LoadAccountAction implements Action {
	readonly type = ActionTypes.LOAD_ACCOUNT;
	constructor(public payload?: any) {}
}

export class SetisLoggedInAction implements Action {
	readonly type = ActionTypes.SET_IS_LOGGED_IN;
	constructor(public payload?: any) {}
}

export class EditFormDataAction implements Action {
	readonly type = ActionTypes.EDIT_FORMDATA;
	constructor(public payload?: any) {}
}

export class SetIsNewAction implements Action {
	readonly type = ActionTypes.SET_IS_NEW;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export class SetConditionsReadAction implements Action {
	readonly type = ActionTypes.SET_CONDITIONS_READ;
	constructor(public payload?: any) {}
}

export type Actions =
	| StoreBearerAction
	| LoadAccountAction
	| SetisLoggedInAction
	| EditFormDataAction
	| SetIsNewAction
	| SetIsLoadingAction
	| SetConditionsReadAction;