import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActionWithPayload } from '../../interfaces/actionWithPayload';
import { ActionTypes, Actions } from '../../store/reservations/reservations.actions';
import { StateInterface } from '../../store/state.model';
import { HeadersHelper, ItemsHelper } from '../../helpers';
import { getState, tassign } from '../../tools';

import 'rxjs/Rx';

@Injectable()

/**
 * Class representing the Reservations service.
 */
export class ReservationsService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Retrieve categories data from server and dispatch action
	 * to update the store accordingly.
	 * @return {Observable}
	 */
	hydrateCategories(): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http.get(`${this.apiEndpoint}reservations`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to load categories.
	 * @param {object} result
	 * @return {Actions}
	 */
	loadCategories(result: any): Actions {
		const { categories } = getState(this.store);

		return {
			type: ActionTypes.LOAD_RESERVATIONS,
			payload: tassign(categories, {
				items: ItemsHelper.parseItems(result),
				isLoading: false
			})
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
