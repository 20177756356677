export const routePermissions = {
	'/reservation': ['superusers', 'students', 'employees'],
	'/reservation/filter': ['superusers', 'students', 'employees'],
	'/reservation/overview': ['superusers', 'students', 'employees'],
	'/reservation/active': ['superusers', 'students', 'employees'],
	'/reservation/ict-box': ['superusers', 'employees'], // defective-item
	'/reservation/drop-box': ['superusers', 'employees'], // orders/new
	'/reservation/registration': ['superusers', 'students', 'employees'],
	'/reservation/registration/:id': ['superusers', 'students', 'employees'],
	'/reservation/conditions': ['superusers', 'students', 'employees'],
	'/reservation/confirmation': ['superusers', 'students', 'employees'],
	'/reservation/settings': ['superusers', 'students', 'employees'],

	'/management': ['superusers'],
	'/management/recipes': ['superusers'],
	'/management/recipe': ['superusers'],
	'/management/recipe/:id': ['superusers'],
	'/management/categories': ['superusers'],
	'/management/cabinets': ['superusers'],
	'/management/users': ['superusers'],
	'/management/user': ['superusers'],
	'/management/user/:id': ['superusers'],
	'/management/reports': ['superusers'],
	'/management/report/:id': ['superusers'],
	'/management/administrators': ['superusers'],
	'/management/customnotifications': ['superusers'],
	'/management/customnotification/:id': ['superusers'],
};
