import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../../../store/state.model';
import { RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { ManagementRecipeEnriched } from '../../../../store/management-recipes/management-recipes.models';
import { AuthenticationService, RegistrationService, RecipesService, ManagementRecipesService, LayoutService } from '../../../../services';

@Component({
	selector: 'clickable-list-item',
	template: require('./clickable-list-item.component.html')
})

export class ClickableListItemComponent {

	/**
	 * @type {string} - The button image.
	 */
	@Input() image: string;

	/**
	 * @type {string} - The button title.
	 */
	@Input() title: string;

	/**
	 * @type {string} - The button subtitle.
	 */
	@Input() subtitle: string;

	/**
	 * @type {number} - The id of the list element.
	 */
	@Input() destinationId: number;

	/**
	 * @type {string} - Destination link
	 */
	@Input() destinationLink: string;

	/**
	 * Constructor.
	 * @param {Router} router
	 * @return {void}
	 */
	constructor(
		private router: Router,
	) {}

	/**
	 * Upon clicking on the item.
	 * @return {void}
	 */
	onClick($event: Event): void {
		const { destinationLink, destinationId } = this;

		$event.preventDefault();

		if (destinationLink && destinationId) {
			setTimeout(() => this.router.navigate([destinationLink, destinationId])); // @TODO do we really need setTimeout?
		}
	}
}
