import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { StateInterface, Locale, Categories, Locations, Cabinets } from '../../../../store/state.model';
import { ManagementRecipeEnriched } from '../../../../store/management-recipes/management-recipes.models';
import { ActionTypes as ManagementRecipesActionTypes } from '../../../../store/management-recipes/management-recipes.actions';
import { LayoutService, ManagementRecipesService, IRecipeRegistration, ManagementRecipeItemsService, IManagementRecipeItem, CabinetPositionsService, RegistrationService, IUsageReportItem } from '../../../../services';
import { RecipesHelper } from '../../../../helpers/recipes/recipes.helper';
import { BaseComponent } from '../../../base/base.component';
import { _, getState } from '../../../../tools';

@Component({
	template: require('./recipe-view.component.html')
})

/**
 * Class representing the RecipeViewComponent component.
 */
export class RecipeViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * The recipe form.
	 * @type {FormGroup}
	 */
	form: FormGroup;

	/**
	 * {Categories} The categories state snapshot.
	 */
	categories: Categories;

	/**
	 * The locations state.
	 * @type {Locations}
	 */
	locations: Locations;

	/**
	 * {Cabinets} The cabinets state snapshot.
	 */
	cabinets: Cabinets;

	/**
	 * {Cabinets} The cabinets state snapshot.
	 */
	cabinetPositions: any = [];

	/**
	 * {Cabinets} The cabinets state snapshot.
	 */
	currentCabinetPosition: any;

	/**
	 * {Cabinets} The cabinets state snapshot.
	 */
	emptyCabinetPositions: any[];

	/**
	 * The current recipe id.
	 * @type {number}
	 */
	recipeId: number;

	/**
	 * The current recipe (if applicable)
	 * @type {RecipeEnriched}
	 */
	recipe: ManagementRecipeEnriched;

	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;

	/**
	 * Image file from file input
	 * @type {any}
	 */
	imageFile: File;

	selectedUnit: string = 'hour';

	recipeItems: IManagementRecipeItem[] = [];
	recipeRegistrations: IRecipeRegistration[];

	/**
	 * Constructor.
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private layoutService: LayoutService,
		private managementRecipesService: ManagementRecipesService,
		private managementRecipeItemsService: ManagementRecipeItemsService,
		private cabinetPositionsService: CabinetPositionsService,
		private registrationService: RegistrationService,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>,
		private translate: TranslateService,
	) {
		super();
		const { cabinets } = getState(store);

		// Sets cabinets state snapshot
		this.cabinets = cabinets;

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale')).subscribe((locale: Locale) => {
			this.locale = _.cloneDeep(locale);
		}));

		// Subscribes to route params
		this.addSubscription(this.route.params.subscribe(params => {
			this.recipeId = +params['id'] || 0;
		}));

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories')).subscribe((categories: Categories) => {
			this.categories = _.cloneDeep(categories);
		}));

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets')).subscribe((cabinets: Cabinets) => {
			this.cabinets = _.cloneDeep(cabinets);
		}));

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations')).subscribe((locations: Locations) => {
			this.locations = _.cloneDeep(locations);
		}));
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		// let itemsFormFields = {};
		const getDefaultValue = recipeProperty => this.recipe ? this.recipe[recipeProperty] : '';

		window.scrollTo(0, 0);

		setTimeout(() => {
			this.store.dispatch(
				this.layoutService.setIsLoading(false)
			);
		}, 100);

		// Sets current recipe if applicable
		if (this.recipeId) {
			const { items } = getState(this.store).managementRecipes;
			let recipes_available: any = getState(this.store).recipes.items;

			this.recipe = {

				// Adds metadata
				...RecipesHelper.getEnrichedManagementRecipe(
					items.find(recipe => recipe.id === this.recipeId),
					recipes_available.find(recipe => recipe.id === this.recipeId),
					getState(this.store))
			};

			this.managementRecipeItemsService.getItems(this.recipeId).subscribe((data: any) => {
				if (data && data._body) {
					let response: any = JSON.parse(data._body);
					this.recipeItems = response.result || [];
					this.recipeItems.forEach((item: IManagementRecipeItem) => {
						item.form = new FormGroup({
							barcode: new FormControl(item.Barcode, Validators.required),
							descrNl: new FormControl(item.BarcodeDescrNL, Validators.required),
							descrEn: new FormControl(item.BarcodeDescrEN, Validators.required),
						});
					});
				}
			}, (error: any) => {
				console.log('error', error);
			});

			this.managementRecipesService.getRecipeRegistrations(this.recipeId).subscribe((data: any) => {
				if (data && data._body) {
					let response: any = JSON.parse(data._body);
					if (response.success) {
						this.recipeRegistrations = response.result;
					}
				}
			});
		}

		let maxDuration: number = getDefaultValue('maxDuration') / 60 || 0;
		if (maxDuration % 24 === 0) {
			maxDuration = maxDuration / 24;
			this.selectedUnit = 'day';
		}

		let categoryId: number = getDefaultValue('categoryId');
		if (!this.categories.items.find((category: any) => category.id === categoryId)) {
			categoryId = 0;
		}

		// Sets other form fields
		this.form = new FormGroup({

			// Name
			nameNl: new FormControl(getDefaultValue('name').nl, Validators.required),
			nameEn: new FormControl(getDefaultValue('name').en, Validators.required),

			isAvailableTeacher: new FormControl(getDefaultValue('isAvailableTeacher')),
			isAvailableStudent: new FormControl(getDefaultValue('isAvailableStudent')),

			// Availability
			maxLendingHours: new FormControl(maxDuration, Validators.required),
			// isImmediateReturn: new FormControl(getDefaultValue('isImmediateReturn'), Validators.required),

			// Category
			categoryId: new FormControl(categoryId || null, Validators.required),

			// Cabinet
			// cabinetId: new FormControl(getDefaultValue('cabinetId'), Validators.required),
			// cabinetPositionId: new FormControl(getDefaultValue('cabinetPositionId'), Validators.required),

			// Other
			location: new FormControl({ value: getDefaultValue('locationId'), disabled: true }),
			cabinetPosition: new FormControl(-1),
			isActive: new FormControl(getDefaultValue('isActive'), Validators.required),
			isDefect: new FormControl(getDefaultValue('isDefect') || false, Validators.required),

            // Description
			descriptionNL: new FormControl(getDefaultValue('description').nl, Validators.nullValidator),
			descriptionEN: new FormControl(getDefaultValue('description').en, Validators.nullValidator),

            // Items (products etc.)
			// ...itemsFormFields
		});

		this.cabinetPositionsService.hydrateCabinetPositions().subscribe((data: any) => {
			if (data && data._body) {
				let response: any = JSON.parse(data._body);
				this.cabinetPositions = response.result || [];
				this.emptyCabinetPositions = this.cabinetPositions.filter(item => !item.RecipeID);

				let cabinetPosition: any = this.cabinetPositions.find(item => {
					if (this.recipe && item.RecipeID !== this.recipe.id) {
						return false;
					}
					this.currentCabinetPosition = item;
					return this.recipe && item.RecipeID === this.recipe.id;
				});
				this.form.patchValue({
					cabinetPosition: cabinetPosition ? cabinetPosition.ID : -1
				});
			}
		}, (error: any) => {
			console.log('Error', error);
		});

		// Sets page header navigation
		this.store.dispatch(this.layoutService.editLayout({
			leftNav: {
					label: 'recipes-text',
					handler: () => this.router.navigate(['/management/recipes'])
				}
			})
		);
	}

	/**
	 * Return wether or not should focus on a specific field.
	 * @param {string} field
	 * @return {boolean}
	 */
	getShouldFocus(field: string): boolean {
		const { isValidated, form } = this;
		const fields: any =  Object.keys(form.controls);

		let isOtherFieldsValid = true;

		// Checks if there are any invalid fields before this one
		fields.slice(0, fields.findIndex(control => control === field))
		.map((control) => {
			if (!form.get(control).valid) {
				isOtherFieldsValid = false;
			}
		});
		return (isValidated && isOtherFieldsValid && !form.get(field).valid);
	}

	setImageFile(event): void {
		this.imageFile = event.target.files[0];
	}

	onChangeUnit(unit: any): void {
		this.selectedUnit = unit;
	}

	addItem(recipeItem: IManagementRecipeItem): void {
		if (!recipeItem) {
			recipeItem = {
				RecipeID: this.recipeId,
				ID: -1,
				Barcode: '',
				BarcodeDescrNL: '',
				BarcodeDescrEN: '',
				tempId: Math.floor(Math.random() * 100000)
			};
		}
		recipeItem.form = new FormGroup({
			barcode: new FormControl(recipeItem.Barcode, Validators.required),
			descrNl: new FormControl(recipeItem.BarcodeDescrNL, Validators.required),
			descrEn: new FormControl(recipeItem.BarcodeDescrEN, Validators.required),
		});
		this.recipeItems.push(recipeItem);
	}

	/**
	 * Upon submitting the form.
	 * @return {void}
	 */
	onSubmit(event?: any): void {
		if (event) {
			event.preventDefault();
		}

		if (this.form.valid && ((!this.recipe && !!this.imageFile) || !!this.recipe)) {
			this.isValidated = true;
			this.isSubmitted = true;

			let recipeObject: any = {
				CategoryID: parseInt(this.form.value.categoryId),
				DescrNL: this.form.value.nameNl,
				DescrEN: this.form.value.nameEn,
				DescriptionNL: this.form.value.descriptionNL,
				DescriptionEN: this.form.value.descriptionEN,
				MaxLendingHours: Math.round(parseInt(this.form.value.maxLendingHours)) * (this.selectedUnit === 'day' ? 24 : 1),
				// ImmediateReturn: !!this.form.value.isImmediateReturn,
				Active: !!this.form.value.isActive,
			};
			if (this.recipeId) {
				recipeObject.Defect = !!this.form.value.isDefect;
			}
			if (this.form.value.isAvailableTeacher) {
				recipeObject.AvailableForTeacher = this.form.value.isAvailableTeacher;
			}
			if (this.form.value.isAvailableStudent) {
				recipeObject.AvailableForStudent = this.form.value.isAvailableStudent;
			}

			if (!this.currentCabinetPosition || parseInt(this.form.value.cabinetPosition) !== this.currentCabinetPosition.ID) {
				if (this.currentCabinetPosition && parseInt(this.form.value.cabinetPosition) !== -1) {
					this.cabinetPositionsService.setCabinetPositionRecipe(
						this.currentCabinetPosition.ID,
						-1
					).subscribe();
				}

				if (parseInt(this.form.value.cabinetPosition) > 0) {
					this.cabinetPositionsService.setCabinetPositionRecipe(
						parseInt(this.form.value.cabinetPosition),
						this.recipe.id
					).subscribe();
				}
			}

			if (this.imageFile) {
				recipeObject.Image = this.imageFile;
			}

			if (this.recipe) {
				this.managementRecipesService.updateRecipe(this.recipe.id, recipeObject).subscribe((response: any) => {
					if (response && response.success) {
						this.router.navigate(['/management/recipes']);
					} else {
						this.store.dispatch({
							type: ManagementRecipesActionTypes.SET_IS_LOADING,
							payload: false
						});
						console.log('Error', response);
						this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
							alert(translation);
						});
					}
				}, (error: any) => {
					this.store.dispatch({
						type: ManagementRecipesActionTypes.SET_IS_LOADING,
						payload: false
					});
					console.log('Error', error);
				});
			} else {
				this.managementRecipesService.newRecipe(recipeObject).subscribe((response: any) => {
					if (response && response.success) {
						this.router.navigate(['/management/recipes']);
					} else {
						this.store.dispatch({
							type: ManagementRecipesActionTypes.SET_IS_LOADING,
							payload: false
						});
						console.log('Error', response);
						this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
							alert(translation);
						});
					}
				}, (error: any) => {
					this.store.dispatch({
						type: ManagementRecipesActionTypes.SET_IS_LOADING,
						payload: false
					});
					console.log('Error', error);
				});
			}
		}
	}

	deleteRecipe(event?: any): void {
		if (event) {
			event.preventDefault();
		}

		this.translate.get('MANAGEMENT.RECIPES.RECIPE.delete-confirmation-text').subscribe((translation: string) => {
			let isDeleteConfirmed: boolean = confirm(translation);
			if (isDeleteConfirmed) {
				this.managementRecipesService.deleteRecipe(this.recipe.id).subscribe((response: any) => {
					this.router.navigate(['/management/recipes']);
				}, (error: any) => {
					this.store.dispatch({
						type: ManagementRecipesActionTypes.SET_IS_LOADING,
						payload: false
					});
					console.log('Error', error);
				});
			}
		});
	}

	onSubmitItem(item: IManagementRecipeItem, event?: any): void {
		if (event) {
			event.preventDefault();
		}

		let recipeItem: IManagementRecipeItem = {
			RecipeID: this.recipeId,
			Barcode: item.form.value.barcode,
			BarcodeDescrNL: item.form.value.descrNl,
			BarcodeDescrEN: item.form.value.descrEn
		};

		if (item.tempId) {
			this.managementRecipeItemsService.createItem(recipeItem).subscribe((data: any) => {
				if (data && data._body) {
					let response: any = JSON.parse(data._body);
					if (response.success) {
						item.ID = response.insertedID;
						item.tempId = undefined;
					} else {
						this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
							alert(translation);
						});
					}
					console.log('!!!! response', response);
				}
			}, (error: any) => {
				console.log('!!!! error', error);
				this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
					alert(translation);
				});
			});
		} else {
			recipeItem.ID = item.ID;
			this.managementRecipeItemsService.updateItem(recipeItem).subscribe((data: any) => {
				if (data && data._body) {
					let response: any = JSON.parse(data._body);
					if (response.success) {
					} else {
						this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
							alert(translation);
						});
					}
					console.log('!!!! response', response);
				}
			}, (error: any) => {
				console.log('!!!! error', error);
				this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
					alert(translation);
				});
			});
		}
	}

	deleteItem(item: IManagementRecipeItem, event?: any): void {
		if (event) {
			event.preventDefault();
		}

		if (item.tempId) {
			this.recipeItems = this.recipeItems.filter((recipeItem: IManagementRecipeItem) => recipeItem.tempId !== item.tempId);
			return;
		} else {
			this.managementRecipeItemsService.removeItem(item).subscribe((data: any) => {
				if (data && data._body) {
					let response: any = JSON.parse(data._body);
					if (response.success) {
						this.recipeItems = this.recipeItems.filter((recipeItem: IManagementRecipeItem) => recipeItem.ID !== item.ID);
					} else {
						this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
							alert(translation);
						});
					}
					console.log('!!!! response', response);
				}
			}, (error: any) => {
				console.log('!!!! error', error);
				this.translate.get('RESERVATION.GENERAL.generic-error').subscribe((translation: string) => {
					alert(translation);
				});
			});
		}

		// @TODO: remove recipeItem
		console.log('remove recipeItem', item);
	}

	public setStateToReturned(registration: IUsageReportItem): void {
		registration.resetClicked = true;
		console.log(registration);
		this.registrationService.updateRegistration(registration.ID, {
			Status: 4
		}).subscribe(() => {
			registration.Status = 4;
		}, (error: any) => {
			console.log('error', error);
		});
	}
}
