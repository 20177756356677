import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'navigation',
	template: require('./navigation.component.html')
})

export class NavigationComponent {

	/**
	 * Datastructure for the side menu
	 * @type {any[]}
	 */
	public menuSections: any[] = [{
		basePath: '/management/recipe',
		path: '/management/recipes',
		titleKey: 'MANAGEMENT.NAVIGATION.recipes-label',
		subMenus: [{
			path: '/management/recipes',
			titleKey: 'MANAGEMENT.NAVIGATION.recipes-all-label',
		}, {
			path: '/management/recipe',
			titleKey: 'MANAGEMENT.NAVIGATION.recipes-add-label',
		}]
	}, {
		basePath: '/management/categor',
		path: '/management/categories',
		titleKey: 'MANAGEMENT.NAVIGATION.categories-label',
		subMenus: [{
			path: '/management/categories',
			titleKey: 'MANAGEMENT.NAVIGATION.categories-all-label',
		}]
	}, {
		basePath: '/management/cabinet',
		path: '/management/cabinets',
		titleKey: 'MANAGEMENT.NAVIGATION.cabinets-label',
		subMenus: [{
			path: '/management/cabinets',
			titleKey: 'MANAGEMENT.NAVIGATION.cabinets-all-label',
		}]
	}, {
		basePath: '/management/customnotifications',
		path: '/management/customnotifications',
		titleKey: 'MANAGEMENT.NAVIGATION.customnotification-label',
		roles: ['superusers'],
	},
	{
		basePath: '/management/user',
		path: '/management/users',
		titleKey: 'MANAGEMENT.NAVIGATION.users-label',
		subMenus: [{
			path: '/management/users',
			titleKey: 'MANAGEMENT.NAVIGATION.users-all-label',
		}]
	}, {
		basePath: '/management/report',
		path: '/management/reports',
		titleKey: 'MANAGEMENT.NAVIGATION.reports-label',
		subMenus: [{
			path: '/management/reports',
			titleKey: 'MANAGEMENT.NAVIGATION.reports-all-label',
		}, {
			path: '/management/report/0',
			titleKey: 'MANAGEMENT.REPORTS.usage-report.title',
		}]
	// }, {
	// 	basePath: '/management/administrator',
	// 	path: '/management/administrators',
	// 	titleKey: 'MANAGEMENT.NAVIGATION.admins-label',
	// 	subMenus: [{
	// 		path: '/management/administrators',
	// 		titleKey: 'MANAGEMENT.NAVIGATION.admins-all-label',
	// 	}]
	}];

	/**
	 * Class constructor.
	 * @param {Router} router
	 * @return {void}
	 */
	constructor(
		private router: Router
	) {}

	/**
	 * Check if a route is active.
	 * @param {string} route
	 * @return {boolean}
	 */
	isActive(route: string): boolean {
		const { url } = this.router.routerState.snapshot;
		return route === url || url.includes(route);
	}
}
