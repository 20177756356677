import { initialAuthState } from './auth/auth.reducer';
import { initialLocaleState } from './locale/locale.reducer';
import { initialLocationsState } from './locations/locations.reducer';
import { initialCustomNotificationsState } from './customnotifications/customnotifications.reducer';
import { initialCabinetsState } from './cabinets/cabinets.reducer';
import { initialCabinetPositionsState } from './cabinet-positions/cabinet-positions.reducer';
import { initialReservationsState } from './reservations/reservations.reducer';
import { initialCategoriesState } from './categories/categories.reducer';
import { initialRecipesState } from './recipes/recipes.reducer';
import { initialManagementRecipesState } from './management-recipes/management-recipes.reducer';
import { initialFilterState } from './filter/filter.reducer';
import { initialRegistrationState } from './registration/registration.reducer';
import { initialSamlState } from './saml/saml.reducer';
import { initialFeedbackState } from './feedback/feedback.reducer';
import { initialLayoutState } from './layout/layout.reducer';

import { StateInterface } from './state.model';

// Retrieves persistent state snapshot from sessionStorage
let snapshot: StateInterface = JSON.parse(sessionStorage.getItem('stateSnapshot')) || undefined;

if (snapshot) {
	Object.keys(snapshot).map(state => {

		// Disables isLoading state where applicable
		if ('isLoading' in snapshot[state]) {
			snapshot[state].isLoading = false;
		}
	});

	// Resets notifications
	snapshot.feedback.items = [];
}

// Sets and exports initial state
export const InitialState = snapshot || {
	account: initialAuthState,
	locale: initialLocaleState,
	customnotifications: initialCustomNotificationsState,
	locations: initialLocationsState,
	cabinets: initialCabinetsState,
	cabinetPositions: initialCabinetPositionsState,
	reservations: initialReservationsState,
	categories: initialCategoriesState,
	recipes: initialRecipesState,
	managementRecipes: initialManagementRecipesState,
	filter: initialFilterState,
	registration: initialRegistrationState,
	saml: initialSamlState,
	feedback: initialFeedbackState,
	layout: initialLayoutState
};
