import { Injectable } from '@angular/core';
import { ActionTypes, Actions } from '../../store/filter/filter.actions';
import { Filter } from '../../store/state.model';

@Injectable()

/**
 * Class representing the Filter service.
 */
export class FilterService {

	/**
	 * Edit the current filter.
	 * @param {Filter} filter
	 * @return  {Actions}
	 */
	editFilter(filter: Filter): Actions {
		return {
			type: ActionTypes.EDIT_FILTER,
			payload: filter
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return  {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
