import { ActionReducer } from '@ngrx/store';
import { ActionTypes, Actions } from './reservations.actions';
import { ReservationsInterface as Reservations } from './reservations.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialReservationsState: Reservations = {
	items: [],
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Cabinets} state - the current state
 * @return {Cabinets} The new state
 */
export const reservations: ActionReducer<any, Actions> = (state: Reservations = initialReservationsState, action: Actions): Reservations => {
	switch (action.type) {

		case ActionTypes.LOAD_RESERVATIONS:
			return action.payload;

		case ActionTypes.RESET_STATE:
			return initialReservationsState;

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});
	}
	return state;
};
