import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { StateInterface, Categories, ManagementRecipes } from '../../../store/state.model';
import { ActionTypes } from '../../../store/categories/categories.actions';
import { Recipe } from '../../../store/recipes/recipes.models';
import { Category } from '../../../store/categories/categories.models';
import { LayoutService, AuthenticationService, CategoriesService, ManagementRecipesService } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { _, getState } from '../../../tools';

@Component({
	template: require('./categories-view.component.html')
})

/**
 * Class representing the CategoriesViewComponent component.
 */
export class CategoriesViewComponent extends BaseComponent {

	/**
	 * The categories state.
	 * @type {Categories}
	 */
	categories: Categories;

	/**
	 * The recipes state.
	 * @type {Recipes}
	 */
	filteredCategories: Array<Category>;

	/**
	 * The recipes state.
	 * @type {Recipes}
	 */
	recipes: ManagementRecipes;

	currentOpenItem: number = -1;

	categoryLinks: any = {};

	/**
 	 * Constructor.
	 * @param {LayoutService} layoutService
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private categoriesService: CategoriesService,
		private managementRecipesService: ManagementRecipesService,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories')).subscribe((categories: Categories) => {
			this.categories = _.cloneDeep(categories);
			this.filteredCategories = [...this.categories.items];
		}));

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('managementRecipes')).subscribe((recipes: ManagementRecipes) => {
			this.recipes = _.cloneDeep(recipes);
			let recipes_available: any[] = getState(this.store).recipes.items;

			if (this.recipes) {
				this.categories.items.forEach((category: Category) => {
					this.categoryLinks[category.id] = this.recipes.items
					.map((item: Recipe) => {
						return item.categoryId;
					})
					.reduce((value: number, item: number) => {
						if (category.id === item) {
							return value + 1;
						}
						return value;
					}, 0);
				});
			}
		}));
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Resets page heaer navigation
		this.store.dispatch(
			this.layoutService.resetState()
		);
	}

	/**
	 * Apply a search query filter.
	 * @param {string} query
	 */
	applyFilter(query: string): void {
		const { items } = this.categories;
		const { current } = getState(this.store).locale;

		this.filteredCategories = items.filter(category => {
			const { name } = category;
			const hasQuery = (haystack, needle) => haystack.search(new RegExp(needle, 'i')) !== -1;

			if (query) {

				// Searches recipe metadata
				let inName = hasQuery(name[current], query);
				let inCategory = category ? hasQuery(category.name[current], query) : false;

				return (inName || inCategory) || false;
			}
			return true;
		});
	}

	createCategory(values: any): void {
		let descriptions: { DescrNL: string, DescrEN: string } = {
			DescrNL: values.nameNl,
			DescrEN: values.nameEn
		};
		this.categoriesService.createCategory(descriptions).subscribe((data: any) => {
			if (data && data._body) {
				let response: any = JSON.parse(data._body);
				let category: any = {
					id: response.insertedID,
					name: {
						nl: descriptions.DescrNL,
						en: descriptions.DescrEN
					}
				};
				this.categories.items.push(category);
				this.categories.isLoading = false;
				this.store.dispatch({
					type: ActionTypes.ADD_CATEGORY,
					payload: this.categories
				});
			}
		}, (error: any) => {
			console.log('error', error);
		});
	}

	saveChanges(category: any, values: any): void {
		this.categoriesService.updateCategory(category.id, {
			DescrNL: values.nameNl,
			DescrEN: values.nameEn
		}).subscribe((response: any) => {
			let updatedCategory = this.categories.items.find((item: any) => item.id === category.id);
			updatedCategory.name.nl = values.nameNl;
			updatedCategory.name.en = values.nameEn;
			this.categories.isLoading = false;

			this.store.dispatch({
				type: ActionTypes.UPDATE_CATEGORIES,
				payload: this.categories
			});
		}, (error: any) => {
			console.log('error', error);
		});
	}

	deleteItem(category: any): void {
		this.categoriesService.deleteCategory(category.id).subscribe((response: any) => {
			this.categories.items = this.categories.items.filter((item: any) => item.id !== category.id);

			this.store.dispatch({
				type: ActionTypes.DELETE_CATEGORY,
				payload: this.categories
			});
		}, (error: any) => {
			console.log('error', error);
		});
		console.log('delete', category.id);
	}
}
