import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { StateInterface, Account, Locale, Registration } from '../../../store/state.model';
import { LocaleService, LayoutService } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { _ } from '../../../tools';

@Component({
	selector: 'settings',
	template: require('./settings-view.component.html')
})

/**
 * Class representing the SettingsViewComponent component.
 */
export class SettingsViewComponent extends BaseComponent {

	/**
	 * {Account} The account state.
	 */
	account: Account;

	/**
	 * {Locale} The locale state.
	 */
	locale: Locale;

	/**
	 * @type {Registration} - The registration state.
	 */
	registration: Registration;

	/**
	 * Constructor.
	 * @param {LocaleService} localeService
	 * @param {LayoutService} layoutService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private localeService: LocaleService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
			})
		);

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				leftNav: null,
				rightNav: null
			})
		);
	}

	/**
	 * Change the current language.
	 * @param {string} locale
	 * @return {void}
	 */
	onChangeLanguage(locale: string): void {
		this.store.dispatch(
			this.localeService.setCurrent(locale)
		);
	}

	logout(event: any): void {
		if (event) {
			event.preventDefault();
		}
		sessionStorage.removeItem('stateSnapshot');
		location.reload();
	}
}
