import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Headers, Response } from '@angular/http';
import { map } from 'rxjs/operators';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface } from '../../../store/state.model';
import { LayoutService, AuthenticationService, UsersService, User } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { _, getState, tassign } from '../../../tools';

@Component({
	template: require('./users-view.component.html')
})

/**
 * Class representing the RecipesViewcomponent component.
 */
export class UsersViewComponent extends BaseComponent implements OnInit {

	/**
	 * The users
	 * @type {User[]}
	 */
	users: User[];

	/**
	 * The filtered users
	 * @type {User[]}
	 */
	filteredUsers: User[];

	/**
 	 * Constructor.
	 * @param {LayoutService} layoutService
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private usersService: UsersService,
		private store: Store<StateInterface>
	) {
		super();

		this.usersService.getUsers().pipe(
		map((res: Response) => this.authenticationService.doStoreBearer(res)))
		.subscribe((data: ApiResponse) => {
			const { success, result } = data;
			this.users = _.cloneDeep(result);
			this.filteredUsers = this.users;
		}, (error: any) => {
			console.log('error', error);
		});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Resets page heaer navigation
		this.store.dispatch(
			this.layoutService.resetState()
		);
	}

	/**
	 * Apply a search query filter.
	 * @param {string} query
	 */
	applyFilter(query: string): void {
		this.filteredUsers = this.users.filter(user => {
			const { FirstName, LastName } = user;
			const hasQuery = (haystack, needle) => haystack.search(new RegExp(needle, 'i')) !== -1;

			if (query) {
				// Searches user metadata
				let inFirstName = hasQuery(FirstName, query);
				let inLastName = hasQuery(LastName, query);

				return (inFirstName || inLastName) || false;
			}
			return true;
		});
	}
}
