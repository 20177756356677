import { Injectable, Inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { ActionWithPayload } from '../../interfaces/actionWithPayload';
import { ActionTypes, Actions } from '../../store/layout/layout.actions';
import { StateInterface } from '../../store/state.model';
import { getState } from '../../tools';

import 'rxjs/Rx';

@Injectable()

/**
 * Class representing the Layout service.
 */
export class LayoutService {

	/**
	 * Constructor.
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private store: Store<StateInterface>) {
	}

	/**
	 * Dispatch an action to edit the isActive state per bottomBarItem.
	 * @param {array} userGroups - Roles for the current user
	 * @param {boolean} isAdHoc
	 * @return  {Actions}
	 */
	deactivateBottomBarItems(userGroups: string[], isAdHoc: boolean): Actions {
		const { layout, registration } = getState(this.store);
		const { bottomBarItems } = layout;

		const adHocDeActivate = id => (id === 'ict-box' || id === 'drop-box')
			? isAdHoc : false;

		return {
			type: ActionTypes.EDIT_BOTTOM_BAR_ITEMS,
			payload: bottomBarItems.map(bottomBarItem => ({
				...bottomBarItem,

				// Sets isActive based on current permissions
				isActive: !adHocDeActivate(bottomBarItem.id) && !!userGroups
					.find(role => !!bottomBarItem.roles
						.find(currentRole => currentRole === role))
			}))
		};
	}

	/**
	 * Dispatch an action to edit the current LAYOUT.
	 * @param {object} data
	 * @return  {Actions}
	 */
	editLayout(data: any): Actions {
		const { layout } = getState(this.store);

		return {
			type: ActionTypes.EDIT_LAYOUT,
			payload: { ...layout, ...data }
		};
	}

	/**
	 * Dispatch an action to change the state for 'isCategoriesExpanded'.
	 * @private
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	toggleIsCategoriesExpanded(to: boolean): Actions {
		return {
			type: ActionTypes.TOGGLE_CATEGORIES,
			payload: to
		};
	}

	/**
	 * Dispatch an action to change the state for 'isNavigationvisible'.
	 * @private
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	toggleIsNavigationVisible(to: boolean): Actions {
		return {
			type: ActionTypes.TOGGLE_NAVIGATION,
			payload: to
		};
	}

	/**
	 * Dispatch an action to change the state for 'isBottomBarVisible'.
	 * @private
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	toggleIsBottomBarVisible(to: boolean): Actions {
		return {
			type: ActionTypes.TOGGLE_BOTTOM_BAR,
			payload: to
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return  {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
