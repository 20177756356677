import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { StateInterface, Locale, Account, Locations, Categories, Recipes, Layout, Filter, Registration } from '../../../store/state.model';
import { RecipeBasic } from '../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../interfaces/api-response.interface';
import { AuthenticationService, FilterService, LayoutService, RecipesService } from '../../../services';
import { DateTimeHelper, RecipesHelper } from '../../../helpers';
import { BaseComponent } from '../../base/base.component';
import { _, tassign, getState, Moment } from '../../../tools';

@Component({
	selector: 'filter',
	template: require('./filter-view.component.html')
})

/**
 * Class representing the FilterViewComponent component.
 */
export class FilterViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * @type {Account} - The account state.
	 */
	account: Account;

	/**
	 * @type {Locations} - The locations state.
	 */
	locations: Locations;

	/**
	 * @type {Categories} - The categories state.
	 */
	categories: Categories;

	/**
	 * @type {Recipes} - The recipes state.
	 */
	recipes: Recipes;

	/**
	 * @type {Recipes} - The recipes state.
	 */
	currentRecipes: Recipes;

	/**
	 * @type {Array} - Simpliefied recipes list.
	 */
	simplifiedRecipies: Array<RecipeBasic> = [];

	/**
	 * @type {Layout} - The layout state.
	 */
	layout: Layout;

	/**
	 * @type {Filter} - The filter state.
	 */
	filter: Filter;

	/**
	 * @type {Registration} - The registration state.
	 */
	registration: Registration;

	/**
	 * @type {Categories} - Currently visible categories.
	 */
	visibleCategories: Categories;

	/**
	 * @type {boolean} - The current filter mode.
	 */
	isOverview: boolean = true;

	/**
	 * @type {number} - Number of current recipies.
	 */
	numberOfCurrentRecipies: number;

	/**
	 * Class constructor.
	 * @param {AuthenticationService} authenticationService
	 * @param {FilterService} filterService
	 * @param {RecipesService} recipesService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private authenticationService: AuthenticationService,
		private filterService: FilterService,
		private recipesService: RecipesService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
			})
		);

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);
			})
		);

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('recipes'))
			.subscribe((recipes: Recipes) => {
				this.recipes = _.cloneDeep(recipes);
				this.currentRecipes = _.cloneDeep(recipes);

				if (this.recipes) {

					// Builds a list with simplified recipes (containing only the data the ProductListItem components need)
					if (this.recipes.items.length && this.categories.items.length && this.locations.items.length && this.simplifiedRecipies.length === 0) {
						// Adds metadata (category and location)

						this.currentRecipes.items = this.recipes.items
							.filter(recipe => recipe.maxDuration > 0)
							.map(recipe => RecipesHelper.getEnrichedRecipe({ ...recipe }, {
								categories: this.categories, locations: this.locations
							}))
							.filter(recipe => {
								if (this.filter.date && this.filter.time) {
									const parsedTime = DateTimeHelper.getMinutes(this.filter.time);
									return recipe.availability
										.find(slot => slot.date === this.filter.date && (slot.start <= parsedTime && slot.end >= parsedTime)) || false;
								} else if (this.filter.date) {
									return recipe.availability
										.find(slot => slot.date === this.filter.date) || false;
								}
								return true;
							});

						// Adds simpliefied recipes
						this.simplifiedRecipies = this.getSimplifiedRecipes();
					}
				}
			})
		);

		// Subscribes to the layout state
		this.addSubscription(store.pipe(select('layout'))
			.subscribe((layout: Layout) => {
				this.layout = _.cloneDeep(layout);

				if (this.layout && this.categories) {
					const { isCategoriesExpanded } = this.layout;
					let { items } = this.categories;

					// Leaves only 6 categories if !isCategoriesExpanded
					this.visibleCategories = items.slice(0, isCategoriesExpanded ? items.length : 6);
				}
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);
			})
		);

		// Subscribes to the filter state
		this.addSubscription(store.pipe(select('filter'))
			.subscribe((filter: Filter) => {
				this.filter = _.cloneDeep(filter);

				// Enriches recipes with metadata (category, location) and applies filter(s)
				if (this.recipes && this.recipes.items.length && this.categories.items.length && this.locations.items.length) {

					if (!this.filter.date) { this.setDateFilter(); }
					if (!this.filter.time) { this.setTimeFilter(); }

					const { query, categoryId, locationId, date, time } = this.filter;
					const { current } = this.locale;

					// Applies filters if applicable
					if (query || categoryId || locationId || date || time) {
						this.currentRecipes.items = this.recipes.items

							// Adds metadata (category and location), if applicable
							.map(recipe => !recipe.category ? RecipesHelper.getEnrichedRecipe(recipe, {
								categories: this.categories, locations: this.locations
							}) : recipe)

							// Applies filter for query (if applicable)
							.filter(recipe => {
								const { name, category, location } = recipe;
								const hasQuery = (haystack, needle) => haystack
									.search(new RegExp(needle, 'i')) !== -1;

								if (query) {
									let inName = hasQuery(name[current], query);
									let inCategory = hasQuery(category.name[current], query);
									let inLocation = hasQuery(location.name[current], query);

									return (inName || inCategory || inLocation) || false;
								}
								return true;
							})

							// Applies filter for categoryId (if applicable)
							.filter(recipe => (categoryId && categoryId !== 'all') ? recipe.categoryId === categoryId : true)

							// Applies filter for locationId (if applicable)
							.filter(recipe => locationId ? recipe.locationId === locationId : true)

							// Applies filter for date (if applicable) && Applies filter for time (if applicable)
							.filter(recipe => {
								if (date && time) {
									const parsedTime = DateTimeHelper.getMinutes(time);
									return recipe.availability
										.find(slot => slot.date === date && (slot.start <= parsedTime && slot.end > parsedTime)) || false;
								} else if (date) {
									return recipe.availability
										.find(slot => slot.date === date) || false;
								}
								return true;
							});

						// Adds simpliefied, filtered recipes
						if (this.isOverview) {
							this.numberOfCurrentRecipies = this.currentRecipes.items.length;
							this.simplifiedRecipies = this.getSimplifiedRecipes();
						}
					} else {
						this.recipes.items = this.recipes.items
							// Adds metadata (category and location), if applicable
							.map(recipe => !recipe.category ? RecipesHelper.getEnrichedRecipe(recipe, {
								categories: this.categories, locations: this.locations
							}) : recipe);

						// Adds simpliefied, filtered recipes
						if (this.isOverview) {
							this.simplifiedRecipies = this.getSimplifiedRecipes();
						}
					}
				}
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.setDateFilter();
		this.setTimeFilter();

		// Sets filter mode
		this.isOverview = this.router.routerState.snapshot.url === '/reservation/overview';

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				leftNav: null,
				rightNav: { label: 'reservations-text', handler: () => this.router.navigate(['/reservation/active']) }
			})
		);

		// Refresh list to get most up-to-date list of available recipes
		// @NOTE we only do this if previous call not still running and longert than 30 secs. sinca last call.
		if (this.isOverview && (Moment().unix() - this.recipes.lastUpdated) >= 30 && !this.recipes.isLoading) {
			this.recipesService.hydrateRecipes(JSON.parse(sessionStorage.getItem('cabinetID') || 'null')).pipe(
				map((res: Response) => this.authenticationService.doStoreBearer(res)))
				.subscribe((data: ApiResponse) => {
					const { success, result } = data;

					if (success) {
						this.store.dispatch(
							this.recipesService.loadRecipes(result)
						);
						return;
					}

					this.store.dispatch(
						this.authenticationService.doHandleError(data, this.store.dispatch(
							this.recipesService.setIsLoading(false)
						))
					);
				}, error => this.store.dispatch(this.authenticationService
					.doHandleError(error, this.store.dispatch(
						this.recipesService.setIsLoading(false)
					))
				));
		}
	}

	/**
	 * Remove the onboarding message.
	 * @return {void}
	 */
	dismissOnboarding(): void {
		this.store.dispatch(
			this.authenticationService.setIsNew(false)
		);
	}

	getCurrentTime(stepMinutes) {
		let minutes = DateTimeHelper.getMinutes(Moment(Date.now()).format('HH:mm'));
		minutes = (minutes % stepMinutes !== 0)
			? Math.ceil(minutes / stepMinutes) * stepMinutes
			: minutes;
		return this.registration.isAdHoc ? DateTimeHelper.getTime(minutes - 30) : DateTimeHelper.getTime(minutes);
	}

	hasPassedClosingTime(closingTime, currentTimeInMinutes) {
		const midnightTimeInMinutes = DateTimeHelper.getMinutes('23:59');
		const closingTimeInMinutes = DateTimeHelper.getMinutes(closingTime);
		return currentTimeInMinutes >= closingTimeInMinutes && currentTimeInMinutes <= midnightTimeInMinutes;
	}

	hasPassedMidnight(openingTime, currentTimeInMinutes) {
		const openingTimeInMinutes = DateTimeHelper.getMinutes(openingTime);
		return currentTimeInMinutes < openingTimeInMinutes;
	}

	setDateFilter() {
		const openingTime = '07:00';
		const closingTime = '22:00';
		const currentTimeInMinutes = DateTimeHelper.getMinutes(Moment(Date.now()).format('HH:mm'));
		if (this.hasPassedClosingTime(closingTime, currentTimeInMinutes)) {
			this.filter.date = Moment(Date.now()).add(1, 'days').format('YYYY-MM-DD');
			console.log('hasPassedClosingTime');
		} else if (this.hasPassedMidnight(openingTime, currentTimeInMinutes)) {
			this.filter.date = Moment(Date.now()).format('YYYY-MM-DD');
			console.log('hasPassedMidnight');
		} else {
			this.filter.date = Moment(Date.now()).format('YYYY-MM-DD');
		}
	}

	setTimeFilter() {
		const openingTime = '07:00';
		const closingTime = '22:00';
		const currentTimeInMinutes = DateTimeHelper.getMinutes(Moment(Date.now()).format('HH:mm'));
		if (this.hasPassedClosingTime(closingTime, currentTimeInMinutes)) {
			this.filter.time = openingTime;
		} else if (this.hasPassedMidnight(openingTime, currentTimeInMinutes)) {
			this.filter.time = openingTime;
		} else {
			this.filter.time = this.getCurrentTime(30);
		}
	}

	/**
	 * Togle isCategoriesExpanded.
	 * @param {object} event
	 * @return {void}
	 */
	toggleIsCategoriesExpanded($event: any): void {
		const { isCategoriesExpanded } = this.layout;

		$event.preventDefault();
		this.store.dispatch(
			this.layoutService.toggleIsCategoriesExpanded(!isCategoriesExpanded)
		);
	}

	/**
	 * Apply a new filter.
	 * @param {Filter} newFilter
	 * @return {void}
	 */
	applyFilter(newFilter: Filter): void {

		// Casts locationId to number (if applicable)
		if (Object.keys(newFilter)[0] === 'locationId') {
			newFilter.locationId = parseInt(_.clone(newFilter.locationId)) || null;
		}

		// Edits the filter
		this.store.dispatch(
			this.filterService.editFilter(newFilter)
		);
	}

	/**
	 * Get simplified recipes with less data.
	 * @private
	 * @return {Array}
	 */
	private getSimplifiedRecipes(): Array<RecipeBasic> {
		return this.currentRecipes.items.map(recipe => ({
			id: recipe.id,
			name: recipe.name,
			imgName: recipe.imgName,
			category: { name: recipe.category.name },
			location: { name: recipe.location.name },
			maxDuration: recipe.maxDuration,
			availability: recipe.availability
		}));
	}
}
