import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_RESERVATIONS: '[Reservations] LOAD_RESERVATIONS',
	RESET_STATE: '[Reservations] RESET_STATE',
	SET_IS_LOADING: '[Reservations] SET_IS_LOADING'
};

export class LoadReservationsAction implements Action {
	readonly type = ActionTypes.LOAD_RESERVATIONS;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| LoadReservationsAction
	| SetIsLoadingAction;