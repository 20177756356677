import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

import { StateInterface } from '../../store/state.model';
import { HeadersHelper } from '../../helpers';

import 'rxjs/Rx';

export interface IManagementRecipeItem {
	ID?: number;
	RecipeID: number;
	Barcode: string;
	BarcodeDescrNL: string;
	BarcodeDescrEN: string;
	form?: FormGroup;
	tempId?: number;
}

@Injectable()

/**
 * Class representing the Recipes service.
 */
export class ManagementRecipeItemsService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>,
	) {}

	/**
	 * Retrieve recipeItems data from server
	 * @param  {number} - recipeId [description]
	 * @return {Observable}
	 */
	getItems(recipeId: number): Observable<Response> {
		let url: string = `${this.apiEndpoint}recipeItem/recipe/` + recipeId;
		return this.http.get(url, HeadersHelper.getOptions(this.store));
	}

	createItem(item: IManagementRecipeItem): Observable<Response> {
		let url: string = `${this.apiEndpoint}recipeItem`;
		return this.http.post(url, {
			RecipeID: item.RecipeID,
			BarCode: item.Barcode,
			BarCodeDescrNL: item.BarcodeDescrNL,
			BarCodeDescrEN: item.BarcodeDescrEN
		}, HeadersHelper.getOptions(this.store));
	}

	updateItem(item: IManagementRecipeItem): Observable<Response> {
		let url: string = `${this.apiEndpoint}recipeItem/` + item.ID;
		return this.http.patch(url, {
			BarCode: item.Barcode,
			BarCodeDescrNL: item.BarcodeDescrNL,
			BarCodeDescrEN: item.BarcodeDescrEN
		}, HeadersHelper.getOptions(this.store));
	}

	removeItem(item: IManagementRecipeItem): Observable<Response> {
		let url: string = `${this.apiEndpoint}recipeItem/` + item.ID;
		return this.http.delete(url, HeadersHelper.getOptions(this.store));
	}
}
