import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActionWithPayload } from '../../interfaces/actionWithPayload';

import { ActionTypes, Actions } from '../../store/recipes/recipes.actions';
import { StateInterface } from '../../store/state.model';
import { HeadersHelper, ItemsHelper } from '../../helpers';
import { _, getState, tassign, Moment } from '../../tools';

import 'rxjs/Rx';

@Injectable()

/**
 * Class representing the Recipes service.
 */
export class RecipesService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Retrieve recipes data from server and dispatch action
	 * to update the store accordingly.
	 * @param  {number} - cabinetID [description]
	 * @return {Observable}
	 */
	hydrateRecipes(cabinetID?: number): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		let url: string = `${this.apiEndpoint}recipe/available`;
		if (cabinetID) {
			url += `/` + cabinetID;
		}
		return this.http.get(url, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to load recipes.
	 * @param {object} result
	 * @return  {Actions}
	 */
	loadRecipes(result: any): Actions {
		const { recipes } = getState(this.store);

		return {
			type: ActionTypes.LOAD_RECIPES,
			payload: tassign(recipes, {
				items: ItemsHelper.mapRecipeItems(result),
				lastUpdated: Moment().unix(),
				isLoading: false
			})
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return  {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'selected'.
	 * @param {null|number} to - The new state
	 * @return  {Actions}
	 */
	setSelected(to: null | number): Actions {
		return {
			type: ActionTypes.SET_SELECTED,
			payload: to
		};
	}

	/**
	 * Dispatch an action to change the state for 'lastUpdated'.
	 * @param {number} timeStamp - The new state
	 * @return  {Actions}
	 * @todo unit test
	 */
	setLastUpdated(timeStamp: number | null): Actions {
		return {
			type: ActionTypes.SET_LAST_UPDATED,
			payload: timeStamp
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return  {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
