import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';
import { Store, select } from '@ngrx/store';
// import { Angulartics2 } from 'angulartics2';
import { map } from 'rxjs/operators';
import { StateInterface, Account, Locations, Categories, Filter, Recipes, Registration, Locale } from '../../../store/state.model';
import { RecipeEnriched } from '../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../interfaces/api-response.interface';
import { AuthenticationService, RegistrationService, RecipesService, FeedbackService, LayoutService } from '../../../services';
import { RecipesHelper, DateTimeHelper } from '../../../helpers';
import { BaseComponent } from '../../base/base.component';
import { _, tassign, getState } from '../../../tools';

@Component({
	template: require('./registration-view.component.html')
})

/**
 * Class representing the RegistrationViewComponent component.
 */
export class RegistrationViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * @type {Account} - The account state.
	 */
	account: Account;

	/**
	 * @type {Locations} - The locations state.
	 */
	locations: Locations;

	/**
	 * @type {Categories} - The categories state.
	 */
	categories: Categories;

	/**
	 * @type {Filter} - The recipes state.
	 */
	filter: Filter;

	/**
	 * @type {Recipes} - The recipes state.
	 */
	recipes: Recipes;

	/**
	 * @type {Registration} - The registration state.
	 */
	registration: Registration;

	/**
	 * @type {RecipeEnriched} - The current recipe.
	 */
	recipe: RecipeEnriched;

	/**
	 * @type {number} - The current recipe id.
	 */
	recipeId: number;

	/**
	 * @type {boolean} - The current filter mode
	 */
	isAvailability: boolean = true;

	/**
	 * @type {boolean} - Has the form been submitted or not?
	 */
	isSubmitted: boolean = false;

	/**
	 * @type {boolean}
	 */
	showAvailability: boolean = false;

	/**
	 * @type {boolean} - Has the image src loaded or not?
	 */
	hasImageLoaded: boolean = false;

	/**
	 * Constructor.
	 * @param {string} imagesUrl
	 * @param {AuthenticationService} authenticationService
	 * @param {RegistrationService} registrationService
	 * @param {RecipesService} recipesService
	 * @param {FeedbackService} feedbackService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ImagesUrl') private imagesUrl: string,
		// private angulartics2: Angulartics2,
		private authenticationService: AuthenticationService,
		private registrationService: RegistrationService,
		private recipesService: RecipesService,
		private feedbackService: FeedbackService,
		private layoutService: LayoutService,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>) {
		super();

		// Subscribes to route params
		this.addSubscription(this.route.params
			.subscribe(params => {
				this.recipeId = +params['id'] || 0;
			})
		);

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
			})
		);

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);
			})
		);

		// Subscribes to the filter state
		this.addSubscription(store.pipe(select('filter'))
			.subscribe((filter: Filter) => {
				this.filter = _.cloneDeep(filter);
			})
		);

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('recipes'))
			.subscribe((recipes: Recipes) => {
				this.recipes = _.cloneDeep(recipes);

				if (this.recipes && this.recipes.items.length && this.locations.items.length && this.categories.items.length) {
					const { items } = this.recipes;
					const { date } = this.filter;
					// Finds current recipe
					const recipe = _.cloneDeep(items
						.find(recipe => recipe.id === this.recipeId));
					this.recipe = {

						// Adds category and location metadata
						...RecipesHelper.getEnrichedRecipe(recipe, {
								categories: this.categories, locations: this.locations
							}),

						// Adds availability metadata
						availability: recipe.availability.map((slot, index) => tassign(slot, {
							isSlotOpen: date ? slot.date === date : index === 0 || false
						})),
						// Adds the description property
						description: recipe.description
					};
				}
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);

				if (this.registration) {
					const { isSuccessful, isLoading } = this.registration;

					// Submits a post
					if (isLoading && !this.isSubmitted) {
						this.isSubmitted = true;
						this.doSubmitRegistration();
					}

					// Routes to confirmation when registration was processed succesfully
					if (isSuccessful && !isLoading) {
						this.router.navigate(['/reservation/confirmation']);
					}
				}
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Clears registration state
		this.store.dispatch(
			this.registrationService.resetState()
		);

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				rightNav: null, leftNav: {
					label: 'overview-text',
					handler: () => this.router.navigate(['/reservation/overview'])
				}
			})
		);
	}

	/**
	 * When the view is rendered.
	 * @return {void}
	 */
	ngAfterViewInit(): void {
		setTimeout(() => {

			// Toggles showing availability
			this.showAvailability = true;

			// Fetch most recent recipes to get up-to-date availability
			this.recipesService.hydrateRecipes(JSON.parse(sessionStorage.getItem('cabinetID') || 'null')).pipe(
				map((res: Response) => this.authenticationService.doStoreBearer(res)))
				.subscribe((data: ApiResponse) => {
					const { success, result } = data;

					if (success) {
						this.store.dispatch(
							this.recipesService.loadRecipes(result)
						);
						return;
					}

					this.store.dispatch(
						this.authenticationService.doHandleError(data, this.store.dispatch(
							this.recipesService.setIsLoading(false)
						))
					);

				}, error => this.store.dispatch(this.authenticationService
					.doHandleError(error, this.store.dispatch(
						this.recipesService.setIsLoading(false)
					))
				));
		});
	}

	/**
	 * Toggle isSlotOpen for a specific availability slot.
	 * @param {number} slotIndex - The index of the intended availability slot
	 * @return {void}
	 */
	onToggleSlot(slotIndex: number): void {
		let { isSlotOpen } = this.recipe.availability[slotIndex];
		this.recipe.availability.map(slot => slot.isSlotOpen = false);
		this.recipe.availability[slotIndex].isSlotOpen = !isSlotOpen;
	}

	/**
	 * Toggle isAvialability.
	 * @param {boolean} to
	 * @return {void}
	 */
	toggleIsAvailability(to: boolean): void {
		this.isAvailability = to;
	}

	/**
	 * Update the current registration and trigger submitting (post) a slot.
	 * @param {number} slotIndex - The index of the intended availability slot
	 * @param {object} $event
	 * @return {void}
	 */
	onSubmit(slotIndex: number, $event: any): void {
		const { registration, recipes } = getState(this.store);
		const { id } = this.recipe;

		// Sets corresponding date
		const date = recipes.items.find(recipe => recipe.id === id)
			.availability[slotIndex].date;

		// Edits registration (which triggers a submit)
		this.store.dispatch(
			this.registrationService.editRegistration(tassign(registration, {
				recipeId: id,
				slotIndex,
				formData: {
					...registration.formData,
					dateTime: DateTimeHelper.getUnixTimeStamp(date, $event.start),
					endDateTime: DateTimeHelper.getUnixTimeStamp($event.endDate || date, $event.end),
				},
				status: 1, // @TODO will be removed (ask Rick)
				registrationType: 0, // Lending
				isLoading: true,
				isSuccessful: false
			}))
		);

		// this.angulartics2.eventTrack.next({ action: 'orderRecipe', properties: { category: id }});
	}

	/**
	 * Perform a call to submit a registration.
	 * @private
	 * @return {void}
	 */
	private doSubmitRegistration(): void {
		setTimeout(() => {
			this.recipesService.setLastUpdated(null);
			this.registrationService.submitRegistration().pipe(
				map((res: Response) => this.authenticationService.doStoreBearer(res)))
				.subscribe((data: ApiResponse) => {
					const { success } = data;

					if (success) {
						this.store.dispatch(
							this.registrationService.editRegistration(tassign(this.registration, {
								isLoading: false,
								isSuccessful: true
							}))
						);
						return;
					}

					this.store.dispatch(
						this.authenticationService.doHandleError(data, this.store.dispatch(
							this.registrationService.setIsLoading(false)
						))
					);
				}, error => this.store.dispatch(this.authenticationService
					.doHandleError(error, this.store.dispatch(
						this.registrationService.setIsLoading(false)
					))
				));
		});
	}
}
