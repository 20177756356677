import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'filter-pill',
	template: require('./filter-pill.component.html')
})

/**
 * Class representing the FilterPillComponent component.
 */
export class FilterPillComponent {

	/**
	 * {string} The label for the filter pill.
	 */
	@Input() label: string;

	/**
	 * {EventEmitter} The component remove event emitter.
	 */
	@Output() remove: EventEmitter<any> = new EventEmitter();

	/**
	 * Emit remove event to the parent component.
	 * @return {void}
	 */
	onRemove(): void {
		this.remove.emit();
	}
}
