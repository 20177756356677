import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';

import { StateInterface, Filter } from '../../../../store/state.model';
import { RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { ManagementRecipeEnriched } from '../../../../store/management-recipes/management-recipes.models';
import { AuthenticationService, RegistrationService, RecipesService, ManagementRecipesService, LayoutService } from '../../../../services';
import { Moment } from '../../../../tools';

@Component({
	selector: 'product-list-item',
	template: require('./product-list-item.component.html')
})

export class ProductListItemComponent {

	/**
	 * @type {string} - The account state.
	 */
	@Input('recipe') recipe: RecipeEnriched | ManagementRecipeEnriched;

	/**
	 * @type {boolean}
	 */
	@Input() isNotSelected: boolean;

	/**
	 * @type {string} - Destination link
	 */
	@Input() destinationLink: string = '/reservation/registration';

	/**
	 * @type {boolean} - Has the image src loaded or not?
	 */
	hasImageLoaded: boolean = false;

	/**
	 * @type {number} - Starting time in unix timestamp in seconds when the reservation begins
	 */
	startTimestamp: number;

	/**
	 * @type {string} - The desired date of the pickup in YYYY-MM-DD format
	 */
	startDate: string;

	/**
	 * @type {number} - The upper bound of what is allowed as a valid end time of an reservation in unix timestamp in seconds
	 */
	maxEndTimestamp: number;

	/**
	 * @type {string} Format used by the dates in the system
	 */
	dateFormat: string = 'YYYY-MM-DD';

	/**
	 * @type {object} - The datePicker element.
	 */
	datePicker: any;

	nextRegistrationStartDT: any;

	/**
	 * Constructor.
	 * @param {string} imagesUrl
	 * @param {RegistrationService} registrationService
	 * @param {RecipesService} recipesService
	 * @param {ManagementRecipesService} managementRecipesService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ImagesUrl') private imagesUrl: string,
		private authenticationService: AuthenticationService,
		private registrationService: RegistrationService,
		private recipesService: RecipesService,
		private managementRecipesService: ManagementRecipesService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>) {
	}

	/**
	 * Upon clicking on the item.
	 * @return {void}
	 */
	onClick($event: Event): void {
		const { destinationLink, recipe } = this;

		$event.preventDefault();
		setTimeout(() => this.router.navigate([destinationLink, recipe.id])); // @todo do we really need setTimeout?
	}

	/**
	 * Returns the most applicable duration unit, keeping track whether it's singular or plural.
	 *
	 * @returns {string} String starting with unit- and the unit itself e.g. day, hour, days and hours
	 */
	getDurationUnit(): string {
		const maxDuration = this.getMaxDuration();
		let maxDurationInDays = Math.round((maxDuration / 60) / 24);
		if (maxDurationInDays === 0) {
			if (maxDuration > 60) {
				return 'unit-hours';
			} else {
				return 'unit-hour';
			}
		} else {
			if (maxDurationInDays > 1) {
				return 'unit-days';
			} else {
				return 'unit-day';
			}
		}
	}

	/**
	 * Formats the duration in a way that makes it presentable to the user.
	 *
	 * @returns {number} The duration in amount of units dictated by the getDurationUnit function
	 */
	getPresentableDuration(): number {
		const maxDuration = this.getMaxDuration();
		let durationUnit = this.getDurationUnit();
		if (durationUnit === 'unit-day' || durationUnit === 'unit-days') {
			return Math.round(maxDuration / 60 / 24);
		} else {
			return Math.round(maxDuration / 60);
		}
	}

	getMaxDuration() {
		const recipe: RecipeEnriched = this.recipe;
		const { date, start, nextRegistrationStartDT } = recipe.availability[0];

		this.nextRegistrationStartDT = nextRegistrationStartDT;

		this.calculateEndTime(date, start);

		return (this.maxEndTimestamp - this.startTimestamp) / 60;
	}

	/**
	 * Recalculates the max end and initial end time and date
	 *
	 * @param {string} date Startdate in YYYY-MM-DD format
	 * @param {number} start Start of the given timeslot in minutes from midnight
	 */
	private calculateEndTime(date: string, start: number) {
		this.startTimestamp = this.getTimestampFromDateTime(date, start);
		this.maxEndTimestamp = this.getMaxEndTimestamp(this.startTimestamp, this.recipe.maxDuration, this.nextRegistrationStartDT);
	}

		/**
	 * Returns the start timestamp which is a composition of the startdate and the starttime.
	 *
	 * @param {string} startDate Date which signifies the start of the timeslot in YYYY-MM-DD format
	 * @param {number} startTime Time in minutes relative to the start of the day
	 * @returns {number} unix timestamp in seconds
	 */
	private getTimestampFromDateTime(startDate: string, startTime: number): number {
		return Moment(startDate, this.dateFormat).add(startTime, 'minutes').unix();
	}

	/**
	 * Calculate the maximum allowed endtime, based on the maximum allowed duration and the next registration
	 * @param {number} startTimestamp tartTimestamp The start of the reservation in unix timestamp in seconds
	 * @param {number} maxDuration The maximum length of a reservation in minutes
	 * @param {number} nextRegistration The next reservation in unix timestamp in seconds
	 * @returns {number}
	 */
	private getMaxEndTimestamp(startTimestamp: number, maxDuration: number, nextRegistration: number): number {
		return this.limit(startTimestamp + maxDuration * 60, nextRegistration);
	}

	/**
	 * Caps input to the value of the limit
	 * @param {number} input value which should not be beyond the limit
	 * @param {number} limit the upper limit to which input should abide
	 * @returns {number}
	 */
	private limit(input: number, limit: number): number {
		if (input > limit) {
			return limit;
		}
		return input;
	}
}
