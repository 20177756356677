import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { FeedbackService } from '../services';
import { UseTranslationPipe } from '../pipes';
import { StateInterface } from '../store/state.model';
import { getState } from '../tools';

@Injectable()

/**
 * Class representing the AdHoc AuthGuard.
 */
export class AdHocAuthGuard implements CanActivate {

	/**
 	 * Class constructor.
	 * @param {FeedbackService} feedbackService
 	 * @param {Router} router
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private feedbackService: FeedbackService,
		private router: Router,
		private store: Store<StateInterface>) {
	}

	canActivate(nextUrl: ActivatedRouteSnapshot, current: RouterStateSnapshot): boolean | Observable<boolean> {
		const { isAdHoc } = getState(this.store).registration;
		const useTranslation = new UseTranslationPipe(this.store);

		if (!isAdHoc) {
			return true;
		}

		// Adds notification
		this.store.dispatch(
			this.feedbackService.addNotification({
				text: useTranslation.transform({
					en: 'No persmission',
					nl: 'Geen toegang'
				})
			})
		);

		// Redirects to /login
		this.router.navigate(['/reservation/overview']);
		return false;
	}
}