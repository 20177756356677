import { ActionReducer } from '@ngrx/store';
import { ActionTypes, Actions } from './recipes.actions';
import { RecipesInterface as Recipes } from './recipes.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialRecipesState: Recipes = {
	items: [],
	selected: null,
	lastUpdated: null,
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Recipes} state - the current state
 * @return {Recipes} The new state
 */
export const recipes: ActionReducer<any, Actions> = (state: Recipes = initialRecipesState, action: Actions): Recipes => {
	switch (action.type) {

		case ActionTypes.LOAD_RECIPES:
			return action.payload;

		case ActionTypes.RESET_STATE:
			return initialRecipesState;

		case ActionTypes.SET_SELECTED:
			return tassign(state, {
				selected: action.payload
			});

		// @TODO unit test
		case ActionTypes.SET_LAST_UPDATED:
			return tassign(state, {
				lastUpdated: action.payload
			});

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});
	}
	return state;
};
