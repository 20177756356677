import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NavItem, BottomBarItem } from '../../../store/layout/layout.models';

@Component({
	selector: 'page-header',
	template: require('./page-header.component.html')
})

/**
 * Class representing the PageHeaderComponent component.
 */
export class PageHeaderComponent {

	/**
	 * @type {array} - Bottom bar items
	 */
	@Input() bottomBarItems: Array<BottomBarItem>;

	/**
	 * @type {NavItem|null} - The left nav.
	 */
	@Input() leftNav: NavItem;

	/**
	 * @type {NavItem|null} - The right nav.
	 */
	@Input() rightNav: NavItem;

	/**
	 * @type {boolean}
	 */
	@Input() isBottomBarVisible: boolean = true;

	/**
	 * @type {boolean} - Application loading state.
	 */
	@Input() isLoading: boolean = false;

	/**
	 * @type {EventEmitter} - The component handler event emitter.
	 */
	@Output() click: EventEmitter<any> = new EventEmitter();

	/**
	 * Emit click change event to the parent component.
	 * @return {void}
	 */
	onClick(handler: any): void {
		this.click.emit({ handler });
	}
}
