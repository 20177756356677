import { Action } from '@ngrx/store';

export const ActionTypes = {
	EDIT_REGISTRATION: '[Registration] EDIT_REGISTRATION',
	EDIT_FORMDATA: '[Registration] EDIT_FORMDATA',
	RESET_STATE: '[Registration] RESET_STATE',
	SET_IS_AD_HOC: '[Registration] SET_IS_AD_HOC',
	SET_IS_LOADING: '[Registration] SET_IS_LOADING'
};

export class EditRegistrationAction implements Action {
	readonly type = ActionTypes.EDIT_REGISTRATION;
	constructor(public payload?: any) {}
}

export class EditFormDataAction implements Action {
	readonly type = ActionTypes.EDIT_FORMDATA;
	constructor(public payload?: any) {}
}

export class SetisAdHocAction implements Action {
	readonly type = ActionTypes.SET_IS_AD_HOC;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| EditRegistrationAction
	| EditFormDataAction
	| SetisAdHocAction
	| SetIsLoadingAction;