import { ActionReducer } from '@ngrx/store';
import { ActionTypes, Actions } from './categories.actions';
import { CategoriesInterface as Categories } from './categories.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialCategoriesState: Categories = {
	items: [],
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Categories} state - the current state
 * @return {Categories} The new state
 */
export const categories: ActionReducer<any, Actions> = (state: Categories = initialCategoriesState, action: Actions): Categories => {
	switch (action.type) {

		case ActionTypes.LOAD_CATEGORIES:
			return action.payload;

		case ActionTypes.RESET_STATE:
			return initialCategoriesState;

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});

		case ActionTypes.UPDATE_CATEGORIES:
			return action.payload;

		case ActionTypes.ADD_CATEGORY:
			return action.payload;

		case ActionTypes.DELETE_CATEGORY:
			return action.payload;
	}
	return state;
};
