import { Moment } from '../../tools';

/**
 * Class representing the DateTimeHelper helper.
 */
export class DateTimeHelper {

	/**
	 * {object} Midnight time.
	 */
	private static midNight: any = Moment().startOf('day');

	/**
	 * Parse a time (string) to minutes past midnight for painless
	 * time comparison calculations.
	 * @static
	 * @param {string} time - The time in Moment 24h format (e.g. '14:30')
	 * @return {number} - The time in minutes past midnight
	 */
	static getMinutes(time: string): number {
		return Moment(time, 'HH:mm').diff(this.midNight, 'minutes');
	}

	/**
	 * Parse minutes (past midnight) to a timestamp string.
	 * @static
	 * @param {number} minutes - The minutes past midnight (e.g. 60)
	 * @return {string} - The time (in 24h format, e.g. '01:00')
	 */
	static getTime(minutes: number): string {
		return Moment(this.midNight).add(minutes, 'minutes').format('HH:mm');
	}

	/**
	 * Parse a unix time stamp to a time string.
	 * @static
	 * @param {number} unixTimeStamp - The unix time stamp (e.g. 1490227200)
	 * @return {number} - The time string (e.g. '01:00')
	 */
	static getTimeByUnixTimeStamp(unixTimeStamp: number): string {
		return Moment.unix(unixTimeStamp).format('HH:mm');
	}

	/**
	 * Parse a unix time stamp to a date string.
	 * @static
	 * @param {number} unixTimeStamp - The unix time stamp (e.g. 1490227200)
	 * @return {number} - The date string (e.g. '23-03-2017')
	 */
	static getDateByUnixTimeStamp(unixTimeStamp: number, format: string = 'DD-MM-YYYY'): string {
		return Moment.unix(unixTimeStamp).format(format);
	}

	/**
	 * Parse a unix time stamp to a date + time string.
	 * @static
	 * @param {number} unixTimeStamp - The unix time stamp (e.g. 1490227200)
	 * @return {number} - The date + time string (e.g. '23-03-2017 - 01:00')
	 */
	static getDateTimeByUnixTimeStamp(unixTimeStamp: number): string {
		return Moment.unix(unixTimeStamp).format('DD-MM-YYYY - HH:mm');
	}

	/**
	 * Parse minutes to hours.
	 * @static
	 * @param {number} minutes - The minutes past midnight (e.g. 60)
	 * @return {number} - The amount of hours (rounded)
	 */
	static getHours(minutes: number): number {
		return Math.round(minutes / 60);
	}

	/**
	 * Parse Y-M-D date to D-M-Y date.
	 * @static
	 * @param {string} date
	 * @param {string} format - The format of the date to parse (usually YYYY-MM-DD)
	 * @return {string} - The parsed date (DD-MM-YYYY)
	 */
	static getDate(date: string, format: string = 'YYYY-MM-DD'): string {
		return Moment(date, format).format('DD-MM-YYYY');
	}

	/**
	 * Get a unix timestamp for a certain date and time.
	 * @param {string} date
	 * @param {number} minites - The minutes past midnight (e.g. 60)
	 * @param {} - The unix timestamp (e.g. '1490173200')
	 */
	static getUnixTimeStamp(date: string, minutes: number): number {
		return Moment(`${this.getTime(minutes)} ${date}`, 'HH:mm YYYY-MM-DD').unix();
	}

	/**
	 * Get today's date.
	 * @return {string} - e.g. '2017-06-19'
	 */
	static getToday(): string {
		return Moment().format('YYYY-MM-DD');
	}
}
