import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

// Custom modules
import { SharedModule } from '../shared/shared.module';

// Reducers, store and state
import { rootReducers, metaReducers } from '../../store/root.reducer';
import { InitialState } from '../../store/initial-state';

// Services
import { AuthenticationService, LocaleService, LocationsService, CategoriesService, RecipesService, CabinetsService, CabinetPositionsService, ReservationsService, FilterService, RegistrationService, FeedbackService, LayoutService } from '../../services';

// Container components
import { ReservationViewComponent } from '../../components/reservation-view/reservation-view.component';
import { FilterViewComponent } from '../../components/reservation-view/filter-view/filter-view.component';
import { OrderViewComponent } from '../../components/reservation-view/order-view/order-view.component';
import { ActiveViewComponent } from '../../components/reservation-view/active-view/active-view.component';
import { RegistrationViewComponent } from '../../components/reservation-view/registration-view/registration-view.component';
import { ConditionsViewComponent } from '../../components/reservation-view/conditions-view/conditions-view.component';
import { ConfirmationViewComponent } from '../../components/reservation-view/confirmation-view/confirmation-view.component';
import { SettingsViewComponent } from '../../components/reservation-view/settings-view/settings-view.component';

// Presentational components
import { OnboardingComponent } from '../../components/reservation-view/filter-view/onboarding/onboarding.component';
import { AvailabilitySlotComponent } from '../../components/reservation-view/registration-view/availability-slot/availability-slot.component';
import { FilterFormComponent } from '../../components/reservation-view/filter-view/filter-form/filter-form.component';
import { FilterResultsComponent } from '../../components/reservation-view/filter-view/filter-results/filter-results.component';
import { FilterPillComponent } from '../../components/reservation-view/ui/filter-pill/filter-pill.component';
import { SlotDateComponent } from '../../components/reservation-view/registration-view/availability-slot/slot-date/slot-date.component';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/i18n/', '.json'),
				deps: [HttpClient]}
		}),
		StoreModule.forRoot(
			rootReducers, {
				initialState: InitialState,
				metaReducers: metaReducers
			}
		),
		SharedModule
	],
	declarations: [
		ReservationViewComponent,
		RegistrationViewComponent,
		ConditionsViewComponent,
		ConfirmationViewComponent,
		SettingsViewComponent,
		FilterViewComponent,
		OrderViewComponent,
		ActiveViewComponent,
		OnboardingComponent,
		AvailabilitySlotComponent,
		FilterFormComponent,
		FilterResultsComponent,
		FilterPillComponent,
		SlotDateComponent
	],
	providers: [
		AuthenticationService,
		LocaleService,
		LocationsService,
		ReservationsService,
		CategoriesService,
		RecipesService,
		CabinetsService,
		CabinetPositionsService,
		FilterService,
		RegistrationService,
		FeedbackService,
		LayoutService
	]
})

export class ReservationModule {}
