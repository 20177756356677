import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { StateInterface, Locale } from '../../../../store/state.model';
import { ReportsService, IUsageReportItem, RegistrationService } from '../../../../services';
import { RecipesHelper } from '../../../../helpers/recipes/recipes.helper';
import { BaseComponent } from '../../../base/base.component';
import { _, getState, Moment } from '../../../../tools';

const Flatpickr = require('flatpickr');
const Dutch = require('flatpickr/dist/l10n/nl.js').nl;

@Component({
	template: require('./report-usage-view.component.html')
})

/**
 * Class representing the RecipeViewComponent component.
 */
export class ReportUsageViewComponent extends BaseComponent implements OnInit {
	public activity: IUsageReportItem[];
	public startDate: string = Moment().startOf('day').format('YYYY-MM-DD');
	public endDate: string = Moment().endOf('day').format('YYYY-MM-DD');
	@ViewChild('dateInputStart') dateInputStart: ElementRef;
	@ViewChild('dateInputEnd') dateInputEnd: ElementRef;
	private datePickerStart: any;
	private datePickerEnd: any;

	private locale: Locale;

	/**
	 * Constructor.
	 * @param {LayoutService} layoutService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private reportsService: ReportsService,
		private registrationService: RegistrationService,
		private store: Store<StateInterface>,
	) {
		super();
		this.getReport();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		const flatPickrConfigStart = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.startDate,
			maxDate: this.endDate,
			onChange: (selectedDates, dateStr, instance) => {
				this.startDate = dateStr;
				this.datePickerEnd.set('minDate', this.startDate);
				this.getReport();
			}
		};
		setTimeout(() => {
			this.datePickerStart = new Flatpickr(
				this.dateInputStart.nativeElement,
				this.locale.current === 'nl'
					? { ...flatPickrConfigStart, locale: Dutch }
					: flatPickrConfigStart
			);
		});

		const flatPickrConfigEnd = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.startDate,
			minDate: this.startDate,
			onChange: (selectedDates, dateStr, instance) => {
				this.endDate = dateStr;
				this.datePickerStart.set('maxDate', this.endDate);
				this.getReport();
			}
		};
		setTimeout(() => {
			this.datePickerEnd = new Flatpickr(
				this.dateInputEnd.nativeElement,
				this.locale.current === 'nl'
					? { ...flatPickrConfigEnd, locale: Dutch }
					: flatPickrConfigEnd
			);
		});
	}

	private getReport(): void {
		this.activity = undefined;
		this.reportsService.getUsageReport(Moment(this.startDate).startOf('day').unix(), Moment(this.endDate).endOf('day').unix()).subscribe((data: any) => {
			if (data && data._body) {
				let response: any = JSON.parse(data._body);
				if (response.success) {
					this.activity = response.result || [];
				}
			}
		});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);
	}

	public downloadCSV(): void {
		this.reportsService.downloadReport(Moment(this.startDate).startOf('day').unix(), Moment(this.endDate).endOf('day').unix()).subscribe((data: any) => {
			const csv = data._body;
			this.createCSVFile(csv);
		});
	}

	private createCSVFile(csv: any): void {
		if (window.navigator.msSaveOrOpenBlob) {
			let blob = new Blob([decodeURIComponent(encodeURI(csv))], {
				type: 'text/csv;charset=utf-8;'
			});
			navigator.msSaveBlob(blob, Date.now() + '.csv');
		} else {
			let a = document.createElement('a');
			a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
			a.target = '_blank';
			a.download = Date.now() + '.csv';
			document.body.appendChild(a);
			a.click();
		}
	}

	public setStateToReturned(item: IUsageReportItem): void {
		item.resetClicked = true;
		this.registrationService.updateRegistration(item.ID, {
			Status: 4
		}).subscribe(() => {
			item.Status = 4;
		}, (error: any) => {
			console.log('error', error);
		});
	}
}
