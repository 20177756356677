import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../../../store/state.model';
import { RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { ManagementRecipeEnriched } from '../../../../store/management-recipes/management-recipes.models';
import { AuthenticationService, RegistrationService, RecipesService, ManagementRecipesService, LayoutService } from '../../../../services';

@Component({
	selector: 'product-list-edit-item',
	template: require('./product-list-edit-item.component.html')
})

export class ProductListEditItemComponent {

	/**
	 * @type {string} - The account state.
	 */
	@Input('recipe') recipe: RecipeEnriched | ManagementRecipeEnriched;

	/**
	 * @type {boolean}
	 */
	@Input() isNotSelected: boolean;

	/**
	 * @type {string} - Destination link
	 */
	@Input() destinationLink: string = '/reservation/registration';

	/**
	 * @type {boolean} - Has the image src loaded or not?
	 */
	hasImageLoaded: boolean = false;

	/**
	 * Constructor.
	 * @param {string} imagesUrl
	 * @param {RegistrationService} registrationService
	 * @param {RecipesService} recipesService
	 * @param {ManagementRecipesService} managementRecipesService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ImagesUrl') private imagesUrl: string,
		private authenticationService: AuthenticationService,
		private registrationService: RegistrationService,
		private recipesService: RecipesService,
		private managementRecipesService: ManagementRecipesService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>) {
	}

	/**
	 * Upon clicking on the item.
	 * @return {void}
	 */
	onClick($event: Event): void {
		const { destinationLink, recipe } = this;

		$event.preventDefault();
		setTimeout(() => this.router.navigate([destinationLink, recipe.id])); // @todo do we really need setTimeout?
	}
}
