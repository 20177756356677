import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
// import { Angulartics2 } from 'angulartics2';
import { map } from 'rxjs/operators';

import { StateInterface, Locations, Cabinets, CabinetPositions, Registration } from '../../../store/state.model';
import { CabinetEnriched } from '../../../store/cabinets/cabinets.models';
import { ApiResponse } from '../../../interfaces/api-response.interface';
import { AuthenticationService, RegistrationService, LayoutService } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { ValidationHelper, FormHelper, DateTimeHelper } from '../../../helpers/index';
import { _, tassign, Moment, getState } from '../../../tools';

const Flatpickr = require('flatpickr');
const Dutch = require('flatpickr/dist/l10n/nl.js').nl;

@Component({
	template: require('./order-view.component.html')
})

/**
 * Class representing the OrderViewComponent component.
 */
export class OrderViewComponent extends BaseComponent implements OnInit, AfterViewInit {

	/**
	 * @type {ElementRef} - The element on which to bind the dateTime datepicker.
	 */
	@ViewChild('dateTime') dateTime: ElementRef;

	/**
	 * @type {Registration} - The registration state.
	 */
	registration: Registration;

	/**
	 * @type {Locations} - The locations state.
	 */
	locations: Locations;

	/**
	 * @type {Cabinets} - The cabinets state.
	 */
	cabinets: Cabinets;

	/**
	 * @type {CabinetPositions} - The cabinetPositions state.
	 */
	cabinetPositions: CabinetPositions;

	/**
	 * @type {array} - Filtered, enriched cabinets (containing respective lockers)
	 */
	filteredCabinets: Array<CabinetEnriched>;

	/**
	 * @type {FormGroup} - The login form.
	 */
	form: FormGroup;

	/**
	 * @type {string} - The current route (child route within /reservation)
	 */
	currentRoute: string;

	/**
	 * @type {boolean} - Form submit status.
	 */
	isSubmitted: boolean = false;

	/**
	 * @type {boolean} - Submit call status.
	 */
	isRegistrationSubmitted: boolean = false;

	/**
	 * @type {boolean} - Is validation in order or not?
	 */
	isValidated: boolean = false;

	/**
	 * @type {function} - The getShouldFocus helper method.
	 */
	getShouldFocus: any = FormHelper.getShouldFocus;

	/**
	 * Constructor.
	 * @param {AuthenticationService} authenticationService
	 * @param {RegistrationService} registrationService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		// private angulartics2: Angulartics2,
		private authenticationService: AuthenticationService,
		private registrationService: RegistrationService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>) {
		super();

		// Sets current route
		this.currentRoute = this.router.routerState.snapshot.url
			.split('/').slice(2).join('/');

		// Subscribes to the cabinetPositions state
		this.addSubscription(store.pipe(select('cabinetPositions'))
			.subscribe((cabinetPositions: CabinetPositions) => {
				this.cabinetPositions = _.cloneDeep(cabinetPositions);
			})
		);

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets'))
			.subscribe((cabinets: Cabinets) => {
				this.cabinets = _.cloneDeep(cabinets);

				// Enriches cabinets with cabinetPosition metadata
				if (this.cabinetPositions && this.cabinetPositions.items.length) {
					const { items } = this.cabinetPositions;
					this.cabinets.items = this.cabinets.items.map(cabinet => ({
						...cabinet,

						// Adds cabinetPositions (lockers) for this cabinet
						cabinetPositionItems: items
							.filter(cabinetPosition => cabinetPosition.cabinetId === cabinet.id)
					}));
				}
			})
		);

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);

				if (this.registration) {
					const { isSuccessful, isLoading } = this.registration;

					// Submits a post
					if (isLoading && !this.isRegistrationSubmitted) {
						this.isRegistrationSubmitted = true;

						this.isValidated = false;
						this.isSubmitted = false;

						this.doSubmitRegistration();
					}

					// Routes to confirmation when registration was processed succesfully
					if (isSuccessful && !isLoading) {
						this.isRegistrationSubmitted = false;
						this.router.navigate(['/reservation/confirmation']);
					}
				}
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		const { productName, userNote, destinationName, destinationEmail, locationId, dateTime, endDateTime } = this.registration.formData;
		const formConfig = {
			locationId: new FormControl(locationId, Validators.required),
			dateTime: new FormControl(dateTime, Validators.required),
			endDateTime: new FormControl(endDateTime, Validators.required)
		};

		// Sets form fields for drop box
		if (this.currentRoute === 'drop-box') {
			this.form = new FormGroup({
				destinationName: new FormControl(destinationName, Validators.required),
				destinationEmail: new FormControl(destinationEmail, [Validators.required, ValidationHelper.validateHuEmail]),
				...formConfig,
			});
		}

		// Sets form fields for ICT box
		if (this.currentRoute === 'ict-box') {
			this.form = new FormGroup({
				productName: new FormControl(productName, Validators.required),
				userNote: new FormControl(userNote, Validators.required),
				...formConfig
			});
		}

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				rightNav: null,
				leftNav: {
					label: 'overview-text',
					handler: () => this.router.navigate(['/reservation/overview'])
				}
			})
		);
	}

	/**
	 * Upon view is rendered.
	 * @return {void}
	 */
	ngAfterViewInit(): void {
		const { locale } = getState(this.store);
		const { dateTime, registration } = this;
		const { formData } = registration;

		const patchDateTimeValues = dateStr => {
			const endDateTime = Moment(dateStr, 'Y-M-D HH:mm').add('m', 30).unix();
			this.form.patchValue({ dateTime: Moment(dateStr, 'Y-M-D HH:mm').unix() });
			this.form.patchValue({ endDateTime });
			this.onInputChange('endDateTime', endDateTime);
		};

		const flatPickrConfig = {
			dateFormat: 'Y-m-d H:i',
			altFormat: 'd-m-Y H:i',
			enableTime: true,
			altInput: true,
			time_24hr: true,
			enable: [ dateObj => true ],
			minDate: Moment().format('Y-M-D HH:mm'),

			// Upon changing (and thus selecting) the date
			onChange: (selectedDates, dateStr) => patchDateTimeValues(dateStr)
		};

		// Get default date + time
		const defaultDateTime = formData.dateTime
			? Moment.unix(formData.dateTime).format('Y-M-D HH:mm')
			: Moment().format('Y-M-D HH:mm');

		// Initializes date picker
		const datePicker = new Flatpickr(dateTime.nativeElement, locale.current === 'nl'
			? { ...flatPickrConfig, locale: Dutch } : flatPickrConfig);

		// Sets default date + time (either exisiting date or today)
		datePicker.setDate(defaultDateTime);

		// Patches default date + time to form
		setTimeout(() => patchDateTimeValues(defaultDateTime));
	}

	/**
	 * Upon clicking the conditions link.
	 * @param {object} event
	 * @return {void}
	 */
	onConditionsLinkClick(event: any): void {
		event ? event.preventDefault() : null;

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				leftNav: {
					label: this.currentRoute === 'ict-box'
						? 'report-ict-box-item-text'
						: 'drop-box-text',
					handler: () => this.router.navigate(this.currentRoute === 'ict-box'
						? ['/reservation/ict-box']
						: ['/reservation/drop-box']
					)
				}
			})
		);

		// Routes to conditions
		this.router.navigate(['/reservation/conditions']);
	}

	/**
	 * Upon user changing form input data.
	 * @param {string} field - The form field
	 * @param {any} value - The value
	 * @return {void}
	 */
	onInputChange(field: string, value: any): void {
		const { formData } = this.registration;

		setTimeout(() => {
			value = (field === 'locationId' || field === 'dateTime') ? parseInt(value) : value;
			this.isValidated = false;
			this.store.dispatch(
				this.registrationService.editFormData({
					...formData,
					[field]: value
				})
			);
		});
	}

	/**
	 * Submits the form if the user hits the Enter key.
	 * @param {object} event - The event
	 * @return {void}
	 */
	onKeypress(event: any): void {
		const { isLoading } = this.registration;
		if (event.keyCode === 13 && !isLoading) {
			this.onSubmit();
		}
	}

	/**
	 * Update the current order item registration and trigger submitting (post) a slot.
	 * @return {void}
	 */
	onSubmit(): void {
		const { isLoading, formData } = this.registration;
		let { form, currentRoute } = this;

		this.isValidated = true;
		this.isSubmitted = true;

		if (form.valid && !isLoading) {
			this.store.dispatch(
				this.registrationService.editRegistration(tassign(this.registration, {
					formData: {
						...(formValues => Object.assign({}, formValues, {
							dateTime: formValues.dateTime ? parseInt(formValues.dateTime) : null,
							locationId: formValues.locationId ? parseInt(formValues.locationId) : null,
						}))(form.value),

						// Maps form values for start and end date incase user never changed default dates
						dateTime: parseInt(form.get('dateTime').value)
					},
					status: currentRoute === 'ict-box' ? 5 : 1, // @TODO will be removed (ask Rick)
					registrationType: currentRoute === 'ict-box' ? 3 : 2,
					isLoading: true,
					isSuccessful: false
				}))
			);
		}

		// this.angulartics2.eventTrack.next({ action: 'orderBox', properties: { category: (currentRoute === 'ict-box' ? 'ICTBox' : 'DropBox') }});
	}

	/**
	 * Perform a call to submit a registration.
	 * @private
	 * @return {void}
	 */
	private doSubmitRegistration(): void {
		const { registration } = getState(this.store);

		this.registrationService.submitRegistration().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success } = data;

				if (success) {
					this.store.dispatch(
						this.registrationService.editRegistration(tassign(registration, {
							isLoading: false,
							isSuccessful: true
						}))
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.registrationService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.registrationService.setIsLoading(false)
				))
			));
	}
}
